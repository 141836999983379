import * as rh from './_responseHandlers';
import * as tmy from './_tmyHelpers';
import * as authToken from '../authToken';

import * as responses from 'tummylab-protobuf/js/api.tmy.admin.v1/responses_pb';
import * as requests from 'tummylab-protobuf/js/api.tmy.admin.v1/requests_pb';
import { PartnerWebClinic } from 'tummylab-protobuf/js/api.tmy.admin.v1/partner_web_clinic_pb';

const API_NAME = "tmy.admin.v1";

export function getActiveUsers(excludeIssuedToTummyLab) {
  let url = "/active_users";
  if (excludeIssuedToTummyLab) {
    url += "?exclude_issued_to_tummy_lab=1";
  }
  return tmy.get(url, {
    apiName: API_NAME,
    authToken: authToken.get()
  })
    .then(rh.checkStatus)
    .then(rh.unpackProtobuf("API.TMY.Admin.V1.Responses.GetActiveUsers", responses.GetActiveUsers))
    .then((resp) => {
      return resp.getActiveUsersList();
    });
}

export function getProgramConfigs() {
  return tmy.get("/program_configs", {
    apiName: API_NAME,
    authToken: authToken.get()
  })
    .then(rh.checkStatus)
    .then(rh.unpackProtobuf("API.TMY.Admin.V1.Responses.GetProgramConfigs", responses.GetProgramConfigs))
    .then(resp => resp.getProgramConfigsList());
}

export function getProgramProgresses(userUUID) {
  return tmy.get("/users/" + userUUID + "/program_progresses", {
    apiName: API_NAME,
    authToken: authToken.get()
  })
    .then(rh.checkStatus)
    .then(rh.unpackProtobuf("API.TMY.Admin.V1.Responses.GetUsersProgramProgresses", responses.GetUsersProgramProgresses))
    .then(resp => resp.getProgramProgressesList());
}

export function getAppInstallStats(sinceDays) {
  return tmy.get("/app_install_stats?days=" + sinceDays, {
    apiName: API_NAME,
    authToken: authToken.get()
  })
    .then(rh.checkStatus)
    .then(rh.unpackProtobuf("API.TMY.Admin.V1.Responses.GetAppInstallStats", responses.GetAppInstallStats))
    .then((resp) => {
      return resp.getAppInstallStatsList();
    })
}

export function getExtendableSubscriptions() {
  return tmy.get("/extendable_subscriptions", {
    apiName: API_NAME,
    authToken: authToken.get()
  })
    .then(rh.checkStatus)
    .then(rh.unpackProtobuf("API.TMY.Admin.V1.Responses.GetExtendableSubscriptions", responses.GetExtendableSubscriptions))
    .then((resp) => {
      return resp.getExtendableSubscriptionsList();
    })
}

export function disableExtendableSubscription(subscriptionUUID) {
  return tmy.put("/extendable_subscriptions/" + subscriptionUUID + "/disable_automatic_extension", null, {
    apiName: API_NAME,
    authToken: authToken.get()
  })
    .then(rh.checkStatus)
}

export function extendExtendableSubscription(subscriptionUUID, daysToExtend) {
  return tmy.put("/extendable_subscriptions/" + subscriptionUUID + "/extend?days=" + daysToExtend, null, {
    apiName: API_NAME,
    authToken: authToken.get()
  })
    .then(rh.checkStatus)
}

export function getSpecificSurveyStatistic(surveyUUID) {
  return tmy.get("/survey_stats/" + surveyUUID, {
    apiName: API_NAME,
    authToken: authToken.get(),
  })
    .then(rh.checkStatus)
    .then(rh.unpackProtobuf("API.TMY.Admin.V1.Responses.GetSurveyStat", responses.GetSurveyStat))
    .then((resp) => {
      return resp.getStat();
    })
}

export function getSurveyQuestionStatistic(surveyUUID, questionUUID) {
  return tmy.get("/survey_stats/" + surveyUUID + "/" + questionUUID, {
    apiName: API_NAME,
    authToken: authToken.get(),
  })
    .then(rh.checkStatus)
    .then(rh.unpackProtobuf("API.TMY.Admin.V1.Responses.GetSurveyQuestionStats", responses.GetSurveyQuestionStats))
    .then((resp) => {
      return resp.getStats();
    })
}

export function getIbsSssStatistics(initialSurveyUUID, finalSurveyUUID) {
  return tmy.get("/survey_stats/ibs_sss?initial_survey_uuid=" + initialSurveyUUID + "&final_survey_uuid=" + finalSurveyUUID, {
    apiName: API_NAME,
    authToken: authToken.get(),
  })
    .then(rh.checkStatus)
    .then(rh.unpackProtobuf("API.TMY.Admin.V1.Responses.GetIbsSssStats", responses.GetIbsSssStats))
    .then((resp) => {
      return resp.getStats();
    })
}

export function getPartnerWebOrders() {
  return tmy.get("/partner_web_orders", {
    apiName: API_NAME,
    authToken: authToken.get()
  })
    .then(rh.checkStatus)
    .then(rh.unpackProtobuf("API.TMY.Admin.V1.Responses.GetPartnerWebOrders", responses.GetPartnerWebOrders))
    .then((resp) => {
      return resp.getOrdersList();
    })
}

export function getPartnerWebOrder(orderUUID) {
  return tmy.get(`/partner_web_orders/${orderUUID}`, {
    apiName: API_NAME,
    authToken: authToken.get()
  })
    .then(rh.checkStatus)
    .then(rh.unpackProtobuf("API.TMY.Admin.V1.Responses.GetPartnerWebOrder", responses.GetPartnerWebOrder))
    .then((resp) => resp.getOrder())
}

export function updatePartnerWebOrderInvoiceRecord(orderUUID, invoiceNo, invoicedAt) {
  const msg = new requests.PutPartnerWebOrderInvoiceRecord();
  if (invoiceNo != undefined) {
    msg.setInvoiceNo(invoiceNo);
  }
  if (invoicedAt != undefined) {
    msg.setInvoicedAt(invoicedAt.getTime() / 1000);
  }

  return tmy.put(`/partner_web_orders/${orderUUID}/invoice_record`, msg.serializeBinary(), {
    apiName: API_NAME,
    authToken: authToken.get(),
    messageName: "API.TMY.Admin.V1.Requests.PutPartnerWebOrderInvoiceRecord"
  })
    .then(rh.checkStatus);
}

export function updatePartnerWebOrderPaymentRecord(orderUUID, invoicePayedAt) {
  const msg = new requests.PutPartnerWebOrderPaymentRecord();
  if (invoicePayedAt != undefined) {
    msg.setInvoicePayedAt(invoicePayedAt.getTime() / 1000);
  }

  return tmy.put(`/partner_web_orders/${orderUUID}/payment_record`, msg.serializeBinary(), {
    apiName: API_NAME,
    authToken: authToken.get(),
    messageName: "API.TMY.Admin.V1.Requests.PutPartnerWebOrderPaymentRecord"
  })
    .then(rh.checkStatus);
}

export function getPartnerWebClinics() {
  return tmy.get("/partner_web_clinics", {
    apiName: API_NAME,
    authToken: authToken.get()
  })
    .then(rh.checkStatus)
    .then(rh.unpackProtobuf("API.TMY.Admin.V1.Responses.GetPartnerWebClinics", responses.GetPartnerWebClinics))
    .then((resp) => {
      return resp.getClinicsList();
    })
}

export function getPartnerWebClinic(clinicUUID) {
  return tmy.get("/partner_web_clinics/" + clinicUUID, {
    apiName: API_NAME,
    authToken: authToken.get()
  })
    .then(rh.checkStatus)
    .then(rh.unpackProtobuf("API.TMY.Admin.V1.Responses.GetPartnerWebClinic", responses.GetPartnerWebClinic))
}

export function patchPartnerWebClinic(clinicUUID, newClinic, newProductRelations, newPayerRelations, newSupportDocuments) {
  const msg = new requests.PatchPartnerWebClinic();
  msg.setClinic(newClinic);
  msg.setPayerRelationsList(newPayerRelations);
  msg.setProductRelationsList(newProductRelations);
  msg.setSupportDocumentsList(newSupportDocuments);
  return tmy.patch("/partner_web_clinics/" + clinicUUID, msg.serializeBinary(), {
    apiName: API_NAME,
    authToken: authToken.get(),
    messageName: "API.TMY.Admin.V1.Requests.PatchPartnerWebClinic"
  })
    .then(rh.checkStatus)
}

export function postPartnerWebClinic(newClinic, password) {
  const msg = new requests.PostPartnerWebClinic();
  msg.setClinic(newClinic);
  msg.setPassword(password);
  return tmy.post("/partner_web_clinics/", msg.serializeBinary(), {
    apiName: API_NAME,
    authToken: authToken.get(),
    messageName: "API.TMY.Admin.V1.Requests.PostPartnerWebClinic"
  })
    .then(rh.checkStatus)
}

export function getPartnerWebPayers() {
  return tmy.get("/partner_web_payers", {
    apiName: API_NAME,
    authToken: authToken.get()
  })
    .then(rh.checkStatus)
    .then(rh.unpackProtobuf("API.TMY.Admin.V1.Responses.GetPartnerWebPayers", responses.GetPartnerWebPayers))
    .then((resp) => {
      return resp.getPayersList();
    })
}

export function getPartnerWebPayer(payerUUID) {
  return tmy.get("/partner_web_payers/" + payerUUID, {
    apiName: API_NAME,
    authToken: authToken.get()
  })
    .then(rh.checkStatus)
    .then(rh.unpackProtobuf("API.TMY.Admin.V1.Responses.GetPartnerWebPayer", responses.GetPartnerWebPayer))
    .then((resp) => {
      return resp.getPayer();
    })
}

export function patchPartnerWebPayer(payerUUID, newPayer) {
  const msg = new requests.PatchPartnerWebPayer();
  msg.setPayer(newPayer);
  return tmy.patch("/partner_web_payers/" + payerUUID, msg.serializeBinary(), {
    apiName: API_NAME,
    authToken: authToken.get(),
    messageName: "API.TMY.Admin.V1.Requests.PatchPartnerWebPayer"
  })
    .then(rh.checkStatus)
}

export function postPartnerWebPayer(newPayer) {
  const msg = new requests.PostPartnerWebPayer();
  msg.setPayer(newPayer);
  return tmy.post("/partner_web_payers/", msg.serializeBinary(), {
    apiName: API_NAME,
    authToken: authToken.get(),
    messageName: "API.TMY.Admin.V1.Requests.PostPartnerWebPayer"
  })
    .then(rh.checkStatus)
}

export function getPartnerWebProducts() {
  return tmy.get("/partner_web_products", {
    apiName: API_NAME,
    authToken: authToken.get()
  })
    .then(rh.checkStatus)
    .then(rh.unpackProtobuf("API.TMY.Admin.V1.Responses.GetPartnerWebProducts", responses.GetPartnerWebProducts))
    .then((resp) => {
      return resp.getProductsList();
    })
}

export function getPartnerWebProduct(productUUID) {
  return tmy.get("/partner_web_products/" + productUUID, {
    apiName: API_NAME,
    authToken: authToken.get()
  })
    .then(rh.checkStatus)
    .then(rh.unpackProtobuf("API.TMY.Admin.V1.Responses.GetPartnerWebProduct", responses.GetPartnerWebProduct))
    .then((resp) => {
      return resp.getProduct();
    })
}

export function patchPartnerWebProduct(productUUID, newProduct) {
  const msg = new requests.PatchPartnerWebProduct();
  msg.setProduct(newProduct);
  return tmy.patch("/partner_web_products/" + productUUID, msg.serializeBinary(), {
    apiName: API_NAME,
    authToken: authToken.get(),
    messageName: "API.TMY.Admin.V1.Requests.PatchPartnerWebProduct"
  })
    .then(rh.checkStatus)
}

export function postPartnerWebProduct(newProduct) {
  const msg = new requests.PostPartnerWebProduct();
  msg.setProduct(newProduct);
  return tmy.post("/partner_web_products/", msg.serializeBinary(), {
    apiName: API_NAME,
    authToken: authToken.get(),
    messageName: "API.TMY.Admin.V1.Requests.PostPartnerWebProduct"
  })
    .then(rh.checkStatus)
}

export function getPartnerWebRoutines() {
  return tmy.get("/partner_web_routines", {
    apiName: API_NAME,
    authToken: authToken.get()
  })
    .then(rh.checkStatus)
    .then(rh.unpackProtobuf("API.TMY.Admin.V1.Responses.GetPartnerWebRoutines", responses.GetPartnerWebRoutines))
    .then((resp) => {
      return resp.getRoutinesList();
    })
}

export function getPartnerWebRoutine(routineUUID) {
  return tmy.get("/partner_web_routines/" + routineUUID, {
    apiName: API_NAME,
    authToken: authToken.get()
  })
    .then(rh.checkStatus)
    .then(rh.unpackProtobuf("API.TMY.Admin.V1.Responses.GetPartnerWebRoutine", responses.GetPartnerWebRoutine))
    .then((resp) => {
      return resp.getRoutine();
    })
}

export function patchPartnerWebRoutine(routineUUID, newRoutine) {
  const msg = new requests.PatchPartnerWebRoutine();
  msg.setRoutine(newRoutine);
  return tmy.patch("/partner_web_routines/" + routineUUID, msg.serializeBinary(), {
    apiName: API_NAME,
    authToken: authToken.get(),
    messageName: "API.TMY.Admin.V1.Requests.PatchPartnerWebRoutine"
  })
    .then(rh.checkStatus)
}

export function postPartnerWebRoutine(newRoutine) {
  const msg = new requests.PostPartnerWebRoutine();
  msg.setRoutine(newRoutine);
  return tmy.post("/partner_web_routines/", msg.serializeBinary(), {
    apiName: API_NAME,
    authToken: authToken.get(),
    messageName: "API.TMY.Admin.V1.Requests.PostPartnerWebRoutine"
  })
    .then(rh.checkStatus)
}
