Node.prototype.removeAllChildren = function() {
  while (this.hasChildNodes()) {
    this.removeChild(this.firstChild);
  }
}

Node.prototype.replaceChildren = function(newChild) {
  this.removeAllChildren();
  this.appendChild(newChild);
}

window.sleep = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

Date.prototype.toISODateString = function() {
  return this.toISOString().slice(0, 10);
}
