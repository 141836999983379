import { baseURL, language } from 'config';

export function buildHeaders(options) {
  let headers = {
    Accept: 'application/x-protobuf',
    'Accept-Language': language
  };
  if (options.authToken) {
    headers['Authorization'] = 'Bearer ' + options.authToken;
  }
  if (options.apiName) {
    headers['X-TummyLab-API'] = options.apiName;
  }
  if (options.messageName) {
    headers['X-TummyLab-Message-Class'] = options.messageName;
    headers['Content-Type'] = 'application/x-protobuf';
  }
  return headers;
}

function request(method, path, headerOptions, body = undefined) {
  return fetch(baseURL + path, {
    method: method,
    headers: buildHeaders(headerOptions),
    // Needed for IE10
    body: ArrayBuffer.isView(body) ? body.buffer : body
  });
}

export function get(path, headerOptions) {
  return request('GET', path, headerOptions);
}

export function post(path, body, headerOptions) {
  return request('POST', path, headerOptions, body);
}

export function patch(path, body, headerOptions) {
  return request('PATCH', path, headerOptions, body);
}

export function put(path, body, headerOptions) {
  return request('PUT', path, headerOptions, body);
}

export function del(path, body, headerOptions) {
  return request('DELETE', path, headerOptions, body);
}
