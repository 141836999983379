import entry from './form_entry.html';

import { Modal } from './modal';

/**
 * FormModal extends Modal and makes it super easy to create
 * and use modeals which just has simple input fields.
 *
 * The FormModal is passed a list of formEntries, where each
 * enty is on the form:
 *
 * {
 *  key: "some-unique-id",
 *  label: "Label to show for field",
 *  prefill: "Some value to prefill the field with (OPTIONAL)",
 *  help: "Some help text for the field (OPTIONAL)"
 * }
 *
 * Values can be captured by providing a callback for the onSubmit,
 * this will give you a map of the values from the field, using the
 * provided form entry keys as keys in the map.
 */
export class FormModal extends Modal {
  constructor(container, formEntries = [], {
    title = "",
    description = "",
    positiveButton = "",
    negativeButton = "",
    neutralButton = "",
    onNegative = () => {},
    onSubmit = (values) => { console.log("Submitted form modal with values", values) }
  } = {}) {
    super(container, {
      title: title,
      description: description,
      positiveButton: positiveButton,
      negativeButton: negativeButton,
      neutralButton: neutralButton,
      onNegative: onNegative,
      onPositive: () => {
        if (this.hasEmptyInputs()) {
          return;
        }
        const results = {};
        formEntries.forEach(entry => {
          const value = this.view.querySelector(".form-" + entry["key"]).value;
          results[entry["key"]] = value;
        });
        this.onSubmit(results);
        super.destroy();
      }
    })

    this.onSubmit = onSubmit;

    const br = document.createElement("br");
    this.addContent(br);


    formEntries.forEach(e => {
      const prefill = e["prefill"];
      const help = e["help"];

      const field = document.createElement("div");
      field.classList.add("field");
      field.innerHTML = entry({
        label: e["label"],
        key: e["key"],
        help: help,
      });

      if (help === undefined || help == null || help.trim() == "") {
        field.querySelector(".help").remove();
      }

      if (prefill !== undefined && prefill != "") {
        field.querySelector("input").value = prefill;
      }

      this.addContent(field);
    });

    this.view.querySelectorAll("input").forEach((match) => {
      match.addEventListener("input", () => {
        if (match.value.trim() == "") {
          match.classList.add("is-danger");
        } else {
          match.classList.remove("is-danger");
        }
      })
    })
  }

  hasEmptyInputs() {
    let hasEmpty = false;
    this.view.querySelectorAll("input").forEach((match) => {
      if (match.value.trim() == "") {
        match.classList.add("is-danger");
        hasEmpty = true;
      }
    })
    return hasEmpty;
  }
}