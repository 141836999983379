import template from './index.html';

import localize from 'localize';

import { getSpecificSurveyStatistic, getSurveyQuestionStatistic } from 'api/tmyAdminV1';

import { navigateTo } from 'app';
import ErrorNotification from 'components/notification/error';
import SurveyStat from 'components/survey_statistic';
import QuestionStat from 'components/survey_question_statistic';

const strings = {
  en: {
    title: "Survey statistics",
    loading: "Loading",
  },
  sv: {
    title: "Statistik från enkäter",
    loading: "Laddar",
  }
};

const SURVEYS_TO_SHOW = {
  "90fc7e98-4c99-5ee1-9beb-ea2db66bc350": localize({en: "Initial IBS–SSS survey", sv: "Första IBS–SSS enkäten"}),
  "2c8d15fb-f62b-530f-9830-41ebfe3de50d": localize({en: "Final IBS–SSS survey", sv: "Sista IBS–SSS enkäten"}),
  "3f90b4e6-d89d-51d0-bb97-7108f3449b4f": localize({en: "Initial Wästerläkarna survey", sv: "Första Wästerläkarna enkäten"}),
  "3153d76e-7982-5706-86e5-05028fc15438": localize({en: "Final Wästerläkarna survey", sv: "Sista Wästerläkarna enkäten"})

};

export default function Setup() {
  const container = document.createElement("div") ;
  container.innerHTML = template({
    t: localize(strings),
  });

  container.querySelector("h2").addEventListener("click", (ev) => {
    const extra = { no_header: "true" };
    const surveyUUID = require('querystring').parse(window.location.search.substring(1)).survey_uuid || null;
    if (surveyUUID !== null) {
      extra["survey_uuid"] = surveyUUID;
    }
    const questionUUID = require('querystring').parse(window.location.search.substring(1)).question_uuid || null;
    if (questionUUID !== null) {
      extra["question_uuid"] = questionUUID
    }
    navigateTo("survey_stats", extra);
  });

  const statsContainer = container.querySelector("#stats-container");
  const progress = container.querySelector("progress");

  const surveyUUID = require('querystring').parse(window.location.search.substring(1)).survey_uuid || null;
  if (surveyUUID !== null) {
    const questionUUID = require('querystring').parse(window.location.search.substring(1)).question_uuid || null;
    if (questionUUID !== null) {
      // Show just the specific question
      getSurveyQuestionStatistic(surveyUUID, questionUUID)
      .then((stat) => {
        progress.remove();
        container.appendChild(QuestionStat(stat));
      })
      .catch((err) => {
        progress.remove();
        container.appendChild(ErrorNotification(err));
      });
    } else {
      // Show the specific survey
      getSpecificSurveyStatistic(surveyUUID)
      .then((stat) => {
        progress.remove();
        statsContainer.appendChild(SurveyStat(stat, surveyUUID, SURVEYS_TO_SHOW[surveyUUID]));
      })
      .catch((err) => {
        progress.remove();
        container.appendChild(ErrorNotification(err));
      });
    }
  } else {
    // Show all surveys
    Promise.all(Object.keys(SURVEYS_TO_SHOW).map((uuid) => { return getSpecificSurveyStatistic(uuid).then((resp) => {return [uuid, resp]}) }))
    .then((stats) => {
      progress.remove();
      stats.forEach((statPair, idx) => {
        const surveyUUID = statPair[0];
        const surveyStat = statPair[1];
        const surveyStatView = SurveyStat(surveyStat, surveyUUID, SURVEYS_TO_SHOW[surveyUUID]);
        surveyStatView.querySelectorAll(".question-title").forEach((elem) => {
          // Don't show question titles as hoverable when we list all survey stats
          elem.classList.remove("hoverable");
        });
        statsContainer.appendChild(surveyStatView);
        if (idx != stats.length - 1) {
          const ruler = document.createElement("hr");
          ruler.style.marginTop = "2rem;"
          statsContainer.appendChild(ruler);
        }
      });
    })
    .catch((err) => {
      progress.remove();
      container.appendChild(ErrorNotification(err));
    })
  }

  const noHeader = require('querystring').parse(window.location.search.substring(1)).no_header || null;
  if (noHeader !== null) {
    container.querySelector("h2").remove();
  }

  return container;
}
