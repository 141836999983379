import template from './index.html';

import localize from 'localize';
import { navigateTo } from 'app';

import { getPartnerWebProduct, patchPartnerWebProduct, postPartnerWebProduct } from 'api/tmyAdminV1';
import ErrorNotification from 'components/notification/error';

import uuidv4 from 'uuid';
import { PartnerWebProduct } from 'tummylab-protobuf/js/api.tmy.admin.v1/partner_web_product_pb';

const strings = {
  en: {
    title: "Product",
    name: "Name",
    save: "Save & reload",
    create: "Create product",
    cancel: "Cancel & go back",
    has_logging: "Has logging",
    has_main_program: "Has main program",
    has_clinical_report: "Has clinical report",
    logging_time: "For how long is logging available (in seconds)",
    main_program_time: "For how long is the main program available (in seconds)",
    clinical_report_time: "For how long is the clinical report available (in seconds)",
    has_user_attributes: "Has special user attributes",
    disclaimer: "All changes are immediately visible for our partners after they have been saved.",
  },
  sv: {
    title: "Produkt",
    name: "Namn",
    save: "Spara & ladda om",
    create: "Skapa produkt",
    cancel: "Avbryt & gå tillbaka",
    has_logging: "Har loggning",
    has_main_program: "Har huvudprogrammet",
    has_clinical_report: "Har den kliniska rapporten",
    logging_time: "Hur länge är loggning tillgänglig (i sekunder)",
    main_program_time: "Hur länge är huvudprogrammet tillgängligt (i sekunder)",
    clinical_report_time: "Hur länge är den kliniska rapporten tillgänglig (i sekunder)",
    has_user_attributes: "Har speciella användar-attribut",
    disclaimer: "Alla ändringar blir direkt synliga för våra partners efter att de sparats.",
  }
};

export default function Setup() {
  const container = document.createElement("div");
  const createNewProduct = require('querystring').parse(window.location.search.substring(1)).create_new || null;
  if (createNewProduct !== null) {
    const newProduct = new PartnerWebProduct();
    newProduct.setUuid(uuidv4());
    populateProduct(container, newProduct, true);
    return container;
  }

  const productUUID = require('querystring').parse(window.location.search.substring(1)).product_uuid || null;
  if (productUUID === null) {
    container.appendChild(ErrorNotification(localize({ en: "No product specified", sv: "Ingen produkt angiven"}), false));
    return container;
  }

  const progress = document.createElement("progress");
  progress.classList.add("progress", "is-info");
  progress.setAttribute("max", "100");

  container.appendChild(progress);

  getPartnerWebProduct(productUUID)
  .then((product) => {
    populateProduct(container, product);
  })
  .catch((err) => {
    container.appendChild(ErrorNotification(err));
  })
  .finally(() => {
    progress.remove();
  });

  return container;
}

function populateProduct(container, product, createNew = false) {
  container.innerHTML = template({
    t: localize(strings),
    uuid: product.getUuid(),
    name: product.getName(),
    logging_time: product.getDurationInSecondsForLogging(),
    main_program_time: product.getDurationInSecondsForMainProgram(),
    clinical_report_time: product.getDurationInSecondsForClinicalReport(),
    user_attributes: product.getUserAttributes(),
  });

  if (createNew) {
    container.querySelector("h1.title").innerText = localize({en: "Create Product", sv: "Skapa Produkt"});
  }

  if (product.getHasLogging()) {
    container.querySelector("#has-logging").checked = true;
    container.querySelector("#logging-time").parentElement.parentElement.classList.remove("is-hidden");
  }
  if (product.getHasMainProgram()) {
    container.querySelector("#has-main-program").checked = true;
    container.querySelector("#main-program-time").parentElement.parentElement.classList.remove("is-hidden");
  }
  if (product.getHasClinicalReport()) {
    container.querySelector("#has-clinical-report").checked = true;
    container.querySelector("#clinical-report-time").parentElement.parentElement.classList.remove("is-hidden");
  }
  if (product.getHasUserAttributes()) {
    container.querySelector("#has-user-attributes").checked = true;
    container.querySelector("#user-attributes").parentElement.parentElement.classList.remove("is-hidden");
  }

  container.querySelector("#name-input").addEventListener("change", (ev) => {
    if (ev.target.value == "") {
      ev.target.classList.add("is-danger");
      container.querySelector("#save-button").classList.add("is-disabled");
      container.querySelector("#save-button").setAttribute("disabled", "disabled");
      container.querySelector("#create-button").classList.add("is-disabled");
      container.querySelector("#create-button").setAttribute("disabled", "disabled");
    } else {
      product.setName(ev.target.value);
      ev.target.classList.remove("is-danger");
      container.querySelector("#save-button").classList.remove("is-disabled");
      container.querySelector("#save-button").removeAttribute("disabled");
      container.querySelector("#create-button").classList.remove("is-disabled");
      container.querySelector("#create-button").removeAttribute("disabled")
    }
  });

  container.querySelector("#has-logging").addEventListener("change", (ev) => {
    product.setHasLogging(ev.target.checked);
    if (ev.target.checked) {
      container.querySelector("#logging-time").parentElement.parentElement.classList.remove("is-hidden");
    } else {
      container.querySelector("#logging-time").parentElement.parentElement.classList.add("is-hidden");
    }
  });

  container.querySelector("#logging-time").addEventListener("change", (ev) => {
    product.setDurationInSecondsForLogging(Number(ev.target.value));
  });

  container.querySelector("#has-main-program").addEventListener("change", (ev) => {
    product.setHasMainProgram(ev.target.checked);
    if (ev.target.checked) {
      container.querySelector("#main-program-time").parentElement.parentElement.classList.remove("is-hidden");
    } else {
      container.querySelector("#main-program-time").parentElement.parentElement.classList.add("is-hidden");
    }
  });

  container.querySelector("#main-program-time").addEventListener("change", (ev) => {
    product.setDurationInSecondsForMainProgram(Number(ev.target.value));
  });

  container.querySelector("#has-clinical-report").addEventListener("change", (ev) => {
    product.setHasClinicalReport(ev.target.checked);
    if (ev.target.checked) {
      container.querySelector("#clinical-report-time").parentElement.parentElement.classList.remove("is-hidden");
    } else {
      container.querySelector("#clinical-report-time").parentElement.parentElement.classList.add("is-hidden");
    }
  });

  container.querySelector("#clinical-report-time").addEventListener("change", (ev) => {
    product.setDurationInSecondsForClinicalReport(Number(ev.target.value));
  });

  container.querySelector("#has-user-attributes").addEventListener("change", (ev) => {
    product.setHasUserAttributes(ev.target.checked);
    if (ev.target.checked) {
      container.querySelector("#user-attributes").parentElement.parentElement.classList.remove("is-hidden");
    } else {
      container.querySelector("#user-attributes").parentElement.parentElement.classList.add("is-hidden");
    }
  });

  container.querySelector("#user-attributes").addEventListener("change", (ev) => {
    ev.target.classList.remove("is-danger");
    try {
      JSON.parse(ev.target.value);
      product.setUserAttributes(ev.target.value);
    } catch (e) {
      ev.target.classList.add("is-danger");
    }
  });

  if (createNew) {
    container.querySelectorAll(".toggle-visibility-in-create").forEach((el) => { el.classList.toggle("is-hidden") });
    container.querySelector("#create-button").addEventListener("click", (ev) => {
      const productName = container.querySelector("#name-input").value;
      if (productName == "") {
        console.log("Name can't be empty");
        return;
      }
      console.log("Creating product");
      ev.target.classList.add("is-loading");
      postPartnerWebProduct(product)
      .then(() => {
        navigateTo("view_product", {product_uuid: product.getUuid()});
      })
      .catch((err) => {
        container.querySelector("#save-errors").appendChild(ErrorNotification(err));
      })
      .finally(() => {
        ev.target.classList.remove("is-loading");
      });
    });
  }

  container.querySelector("#save-button").addEventListener("click", (ev) => {
    const newName = container.querySelector("#name-input").value;
    if (newName == "") {
      console.log("Name can't be empty");
      return;
    }
    console.log("Saving product");
    container.querySelector("#save-button").classList.add("is-loading");
    patchPartnerWebProduct(product.getUuid(), product)
    .then(() => {
      navigateTo("view_product", {product_uuid: product.getUuid()});
    })
    .catch((err) => {
      container.querySelector("#save-errors").appendChild(ErrorNotification(err));
    })
    .finally(() => {
      container.querySelector("#save-button").classList.remove("is-loading");
    })
  });

  container.querySelector("#cancel-button").addEventListener("click", (ev) => {
    navigateTo("partner_manager");
  });
}
