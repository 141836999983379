import branchTable from './branching_table.html';
import statTable from './number_table.html';

import localize from 'localize';
import uuidv4 from 'uuid';

import { Survey } from 'tummylab-protobuf/js/api.tmy.v1/survey_pb';
import { SurveyStatistics } from 'tummylab-protobuf/js/api.tmy.admin.v1/survey_statistics_pb';
import Chart from 'chart.js';

/**
 * Renders statistics inside the given container, for the given question
 * and statistic. This is specifically for branching questions.
 *
 * @param {HTMLElement} container
 * @param {Survey.Question} question
 * @param {SurveyStatistics.QuestionStatistic.BranchingStatistic} statistic
 */
export function renderBranchingStats(container, question, statistic) {
  if (question.getConfigCase() != Survey.Question.ConfigCase.BRANCHING_CONFIG) {
    throw "question must have a branching config";
  }
  container.innerHTML = branchTable({
    branch_1_title: question.getBranchingConfig().getAlternative1(),
    branch_1_count: statistic.getAlternative1Count(),
    branch_2_title: question.getBranchingConfig().getAlternative2(),
    branch_2_count: statistic.getAlternative2Count(),
  });
}

/**
 * Renders statistics inside the given container, for the given question
 * and statistic. This is specifically for number and slider questions.
 *
 * @param {HTMLElement} container
 * @param {Survey.Question} question
 * @param {SurveyStatistics.QuestionStatistic.NumberAndSliderStatistic} statistic
 */
export function renderNumberAndSliderStats(container, question, statistic) {
  if (question.getConfigCase() != Survey.Question.ConfigCase.SLIDER_CONFIG &&
      question.getConfigCase() != Survey.Question.ConfigCase.NUMBER_CONFIG) {
    throw "question must have a number or slider config";
  }
  let minValue = 0;   // Slider min value
  let maxValue = 100; // Slider max value
  if (question.getConfigCase() == Survey.Question.ConfigCase.NUMBER_CONFIG) {
    minValue = question.getNumberConfig().getMinimumValue();
    maxValue = question.getNumberConfig().getMaximumValue();
  }

  renderGenericNumberStatistics(container, statistic, minValue, maxValue);
}

/**
 * Renders a histogram for the given statistics in the given container. Histogram parameters are
 * passed to control the appearance of the histogram.
 *
 * @param {HTMLElement} container
 * @param {SurveyStatistics.QuestionStatistic.NumberAndSliderStatistic} statistic
 * @param {Number} min Controls where the histogram starts
 * @param {Number} max Controls where the histogram ends
 * @param {Number} buckets Controls how many chunks the histogram should be split into
 */
export function renderGenericNumberStatistics(container, statistic, min, max, buckets=10) {
  const uniqueCanvasID = uuidv4();
  container.innerHTML = statTable({
    avg: statistic.getAverage().toFixed(2),
    med: statistic.getMedian().toFixed(2),
    min: statistic.getMin(),
    max: statistic.getMax(),
    samples: statistic.getAnswersList().length,
    std_dev: statistic.getStandardDeviation().toFixed(2),
    question_uuid: uniqueCanvasID,
  });

  const histo = createHistogram(statistic.getAnswersList(), min, max, buckets);
  const canvas = container.querySelector(`#chart-${uniqueCanvasID}`);
  renderHistogram(canvas, histo);
}

/**
 * Renders statistics inside the given container, for the given question
 * and statistic. This is specifically for multi choice questions.
 *
 * @param {HTMLElement} container
 * @param {Survey.Question} question
 * @param {SurveyStatistics.QuestionStatistic.MultiChoiceStatistic} statistic
 */
export function renderMultiChoiceStats(container, question, statistic) {
  if (question.getConfigCase() != Survey.Question.ConfigCase.MULTI_CHOICE_CONFIG) {
    throw "question must have a multi choice config";
  }
  const valueToLabel = {};
  question.getMultiChoiceConfig().getAlternativesList().forEach((alt) => {
    valueToLabel[alt.getValue()] = alt.getTitle();
  });
  const histo = {};
  statistic.getAnswerDistributionList().forEach((dist) => {
    histo[valueToLabel[dist.getValue()]] = dist.getAnswerCount();
  });
  container.innerHTML = `<div class="column is-half" style="height: 350px;"><canvas id="chart-${question.getUuid()}" style="margin: auto"></canvas></div>`;
  const canvas = container.querySelector(`#chart-${question.getUuid()}`);
  renderHistogram(canvas, histo);
}

/**
 * Renders statistics inside the given container, for the given question
 * and statistic. This is specifically for free text questions.
 *
 * @param {HTMLElement} container
 * @param {Survey.Question} question
 * @param {SurveyStatistics.QuestionStatistic.FreeTextStatistic} statistic
 */
export function renderFreeTextStats(container, question, statistic) {
  if (question.getConfigCase() != Survey.Question.ConfigCase.FREE_TEXT_CONFIG) {
    throw "question must have a free text config";
  }
  container.innerHTML = `<b>Answers: </b>${statistic.getAnswersList().length}<br>
  <a id="free-text-${question.getUuid()}" class="button is-primary">${localize({en: "View answers", sv: "Se svar"})}</a>`

  const button = container.querySelector(`#free-text-${question.getUuid()}`);
  button.addEventListener("click", (ev) => {
    const win = window.open("", "_blank");
    statistic.getAnswersList().forEach((ans) => {
      win.document.write(`<p>${ans}</p><br /><br />`);
    });
    win.document.close();
    win.focus();
  });
}

/**
 * Creates a histogram by first defining a set of ranges/buckets of
 * equal size. The set size is defined by the bucketCount parameter.
 *
 * All values are checked for which bucket  they belong too, and a
 * counter is incremented for that bucket.
 *
 * A label for each bucket is constructed by combining the lower and
 * higer value of the range, like: "lower-higher".
 *
 * A map/object of the structure
 *  {
 *    "bucket-name": 42
 *  }
 * is returned.
 *
 * @param {Number[]} values
 * @param {Number} min
 * @param {Number} max
 * @param {Number} buckets
 * @returns {Object}
 */
function createHistogram(values, min, max, bucketCount=10) {
  const res = {};
  const step = (max - min)/bucketCount;
  for (let i = 0; i < bucketCount; i++) {
    res[i] = 0;
  }
  values.forEach((val) => {
    for (let i = 0; i < bucketCount; i++) {
      if (val <= min + step*(i + 1)) {
        res[i] += 1;
        break;
      }
    }
  });
  const resWithLabels = {};
  for (let i = 0; i < bucketCount; i ++) {
    const label = (min + step*i) + "-" + (min + step*(i + 1));
    resWithLabels[label] = res[i]
  }
  return resWithLabels;
}

/**
 * Renders the given histogram in the given canvas.
 *
 * The histogram is an object/map of the structure:
 *  {
 *    "bucket-name": 42
 *  }
 *
 * @param {HTMLCanvasElement} canvas
 * @param {Object} histogram
 */
function renderHistogram(canvas, histogram) {
  const data = {
    labels: Object.keys(histogram),
    datasets: [{
      label: localize({en: "Count", sv: "Antal"}),
      data: Object.values(histogram),
      backgroundColor: "#AAD0F5",
      categoryPercentage: 1.0,
      barPercentage: 1.0
    }]
  };
  new Chart(canvas, {
    type: 'bar',
    data: data,
    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: false,
      title: {
        display: true,
        text: "Histogram",
        position: 'left',
        fontSize: 16,
        fontColor: '#5A4660',
        fontFamily: 'Gotham Rounded SSm A, Gotham Rounded SSm B, Segoe UI, Roboto Fira Sans, Droid Sans, Helvetica, Arial, sans-serif',
      },
      scales: {
        xAxes: [{
          display: true,
        }],
        yAxes: [{
          display: true,
          ticks: {
            min: 0,
            precision: 0,
          }
        }],
      }
    }
  });
}
