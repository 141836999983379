import template from './index.html';

import localize from 'localize';

import { navigateTo } from 'app';
import { loginPlatformToken } from 'config'; 
import { postLogin } from 'api/tmyUsersV1';

import ErrorNotification from 'components/notification/error';

export default function Setup(then) {
  const container = document.createElement("div");
  container.innerHTML = template({
    t: localize({
      sv: {
        title: 'Logga in',
        username: 'Användarnamn',
        password: 'Lösenord',
        submit: 'Logga in'
      },
      en: {
        title: 'Login',
        username: 'Username',
        password: 'Password',
        submit: 'Login'
      }
    })
  });

  const form = container.querySelector("form");
  const errorContainer = container.querySelector(".error-container");
  form.addEventListener("submit", async (ev) => {
    ev.preventDefault();

    errorContainer.removeAllChildren();

    const usernameField = form.querySelector("input[name=username]");
    const passwordField = form.querySelector("input[name=password]");

    try {
      const response = await postLogin(
        usernameField.value,
        passwordField.value,
        loginPlatformToken
      );
      then(response.getAuthToken());
    } catch(err) {
      let errorMsg = err.userDisplayableErrorMessage || localize({
        en: "Login failed.",
        sv: "Inloggning misslyckades."
      });

      errorContainer.appendChild(ErrorNotification(errorMsg, true));
    }
  });
  return container;
}
