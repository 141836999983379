import template from './index.html';

import localize from 'localize';
import { navigateTo } from 'app';

import { getPartnerWebRoutine, patchPartnerWebRoutine, postPartnerWebRoutine } from 'api/tmyAdminV1';
import ErrorNotification from 'components/notification/error';

import uuidv4 from 'uuid';
import { PartnerWebRoutine } from 'tummylab-protobuf/js/api.tmy.admin.v1/partner_web_routine_pb';

const strings = {
  en: {
    title: "Routine",
    internal_name: "Internal name",
    internal_name_help: "The internal name is only shown here in the admin panel",
    save: "Save & reload",
    create: "Create routine",
    cancel: "Cancel & go back",
    settings: "Settings",
    requires_inquiry: "Requires an inquiry to be made before ordering",
    requires_inquiry_confirmation: "Inquiry requires confirmation",
    inquiry: "Inquiry",
    inquiry_type: "Inquiry type",
    inquiry_type_help: "Usage: \"Skicka inquiry_type.lowecase() om Tummy Lab-programmet\", is also displayed as-is as a header.",
    pre_order_information: "Pre-order Information",
    pre_order_information_help: "Example: \"Glöm ej remissförfrågan!\". Leave empty to hide.",
    inform_after_order: "Inform payer after order",
    inform_after_order_help: "Examples: \"via Boka Doktorn\", \"genom daganteckning\". Leave empty to hide.",
    has_revisit: "A revisit should be scheduled",
    invoice_via_clinic: "Invoicing should go via the clinic",
    product_price: "Amount to be charged to payer (SEK)",
    disclaimer: "All changes are immediately visible for our partners after they have been saved.",
  },
  sv: {
    title: "Rutin",
    internal_name: "Internt namn",
    internal_name_help: "Det interna namnet visas endast här i adminpanelen",
    save: "Spara & ladda om",
    create: "Skapa rutin",
    cancel: "Avbryt & gå tillbaka",
    settings: "Inställningar",
    requires_inquiry: "Kräver att en förfrågan görs innan en beställning",
    inquiry: "Förfrågan",
    inquiry_type: "Typ av förfrågan",
    inquiry_type_help: "Använding: \"Skicka inquiry_type.lowecase() om Tummy Lab-programmet\", används även utan modifiering som rubrik.",
    requires_inquiry_confirmation: "Förfrågan kräver bekräftelse",
    pre_order_information: "Information före beställning",
    pre_order_information_help: "Exempel: \"Glöm ej remissförfrågan!\". Lämna tom för att dölja.",
    inform_after_order: "Meddela betalare efter beställning",
    inform_after_order_help: "Exempel: \"via Boka Doktorn\", \"genom daganteckning\". Lämna tom för att dölja.",
    has_revisit: "Ett återbesök ska bokas in",
    invoice_via_clinic: "Fakturering sker via kliniken",
    product_price: "Belopp som ska faktureras till slutbetalaren (SEK)",
    disclaimer: "Alla ändringar blir direkt synliga för våra partners efter att de sparats.",
  }
};

const DEFAULT_PRICE = 5000;

export default function Setup() {
  const container = document.createElement("div");

  const createNewRoutine = require('querystring').parse(window.location.search.substring(1)).create_new || null;
  if (createNewRoutine !== null) {
    const newRoutine = new PartnerWebRoutine();
    newRoutine.setUuid(uuidv4());
    populateRoutine(container, newRoutine, true);
    return container;
  }

  const routineUUID = require('querystring').parse(window.location.search.substring(1)).routine_uuid || null;
  if (routineUUID === null) {
    container.appendChild(ErrorNotification(localize({ en: "No routine specified", sv: "Ingen rutin angiven"}), false));
    return container;
  }

  const progress = document.createElement("progress");
  progress.classList.add("progress", "is-info");
  progress.setAttribute("max", "100");

  container.appendChild(progress);

  getPartnerWebRoutine(routineUUID)
  .then((routine) => {
    populateRoutine(container, routine);
  })
  .catch((err) => {
    container.appendChild(ErrorNotification(err));
  })
  .finally(() => {
    progress.remove();
  });

  return container;
}

function validate(container, routine) {
  let hasErrors = false;

  if (routine.getInternalName() == "") {
    container.querySelector("#internal-name-input").classList.add("is-danger");
    hasErrors = true;
  } else {
    container.querySelector("#internal-name-input").classList.remove("is-danger");
  }

  if (routine.getRequiresInquiryBeforeOrder() && routine.getInquiryType() == "") {
    container.querySelector("#inquiry-type").classList.add("is-danger");
    hasErrors = true;
  } else {
    container.querySelector("#inquiry-type").classList.remove("is-danger");
  }

  if (hasErrors) {
    container.querySelector("#save-button").setAttribute("disabled", "disabled");
    container.querySelector("#create-button").setAttribute("disabled", "disabled");
  } else {
    container.querySelector("#save-button").removeAttribute("disabled")
    container.querySelector("#create-button").removeAttribute("disabled")
  }
  return !hasErrors;
}

function updateDetailsViewsVisibility(container, routine) {
  if (routine.getRequiresInquiryBeforeOrder()) {
    container.querySelector(".inquiry-details").classList.remove("is-hidden");
  } else {
    container.querySelector(".inquiry-details").classList.add("is-hidden");
  }

  if (routine.getHasInvoicingViaClinic()) {
    container.querySelector(".invoicing-details").classList.remove("is-hidden");
  } else {
    container.querySelector(".invoicing-details").classList.add("is-hidden");
  }
}

// Intentionally not localized
function updateInquiryType(container, routine) {
  // Don't override user supplied values
  if (["", "Remissförfrågan", "Remissförfrågan för kännedom"].indexOf(routine.getInquiryType()) === -1) {
    return;
  }

  if (routine.getRequiresInquiryConfirmationBeforeOrder()) {
    routine.setInquiryType("Remissförfrågan");
  } else if (routine.getRequiresInquiryBeforeOrder()) {
    routine.setInquiryType("Remissförfrågan för kännedom");
  } else {
    routine.setInquiryType("");
  }

  container.querySelector("#inquiry-type").value = routine.getInquiryType();
}

function populateRoutine(container, routine, createNew = false) {
  let price = routine.getProductPriceForFinalPayer();
  if (createNew) {
    price = DEFAULT_PRICE;
    routine.setProductPriceForFinalPayer(price);
  }

  container.innerHTML = template({
    t: localize(strings),
    uuid: routine.getUuid(),
    internal_name: routine.getInternalName(),
    pre_order_information: routine.getPreOrderInformation(),
    should_inform_after_order: routine.getShouldInformAfterOrder(),
    inquiry_type: routine.getInquiryType(),
    product_price: price,
  });

  if (createNew) {
    container.querySelector("h1.title").innerText = localize({en: "Create Routine", sv: "Skapa Rutin"});
  }

  // Initially populate views
  if (routine.getRequiresInquiryBeforeOrder()) {
    container.querySelector("#requires-inquiry").checked = true;
  }

  if (routine.getRequiresInquiryConfirmationBeforeOrder()) {
    container.querySelector("#requires-inquiry-confirmation").checked = true;
  }

  if (routine.getHasRevisit()) {
    container.querySelector("#has-revisit").checked = true;
  }

  if (routine.getHasInvoicingViaClinic()) {
    container.querySelector("#has-invoicing-via-clinic").checked = true;
  }

  updateDetailsViewsVisibility(container, routine);

  // Setup event listeners
  container.querySelector("#internal-name-input").addEventListener("change", (ev) => {
    routine.setInternalName(ev.target.value) ;

    validate(container);
  });

  container.querySelector("#requires-inquiry").addEventListener("change", (ev) => {
    routine.setRequiresInquiryBeforeOrder(ev.target.checked);

    updateInquiryType(container, routine);
    updateDetailsViewsVisibility(container, routine);
    validate(container, routine);
  });

  container.querySelector("#requires-inquiry-confirmation").addEventListener("change", (ev) => {
    routine.setRequiresInquiryConfirmationBeforeOrder(ev.target.checked);

    updateInquiryType(container, routine);
    validate(container, routine);
  });

  container.querySelector("#inquiry-type").addEventListener("change", (ev) => {
    routine.setInquiryType(ev.target.value);

    validate(container, routine);
  });

  container.querySelector("#pre-order-information").addEventListener("change", (ev) => {
    routine.setPreOrderInformation(ev.target.value);

    validate(container, routine);
  });

  container.querySelector("#should-inform-after-order").addEventListener("change", (ev) => {
    routine.setShouldInformAfterOrder(ev.target.value);

    validate(container, routine);
  });

  container.querySelector("#has-revisit").addEventListener("change", (ev) => {
    routine.setHasRevisit(ev.target.checked);

    validate(container, routine);
  });

  container.querySelector("#has-invoicing-via-clinic").addEventListener("change", (ev) => {
    routine.setHasInvoicingViaClinic(ev.target.checked);

    updateDetailsViewsVisibility(container, routine);
    validate(container, routine);
  });

  container.querySelector("#product-price").addEventListener("change", (ev) => {
    routine.setProductPriceForFinalPayer(Number(ev.target.value));
    validate(container, routine);
  });


  // Handle create new case
  if (createNew) {
    container.querySelectorAll(".toggle-visibility-in-create").forEach((el) => { el.classList.toggle("is-hidden") });
    container.querySelector("#create-button").addEventListener("click", (ev) => {
      if (!validate(container, routine)) {
        console.log("Validation failed!");
        return;
      }

      console.log("Creating routine");
      ev.target.classList.add("is-loading");
      postPartnerWebRoutine(routine)
      .then(() => {
        navigateTo("view_routine", {routine_uuid: routine.getUuid()});
      })
      .catch((err) => {
        container.querySelector("#save-errors").appendChild(ErrorNotification(err));
      })
      .finally(() => {
        ev.target.classList.remove("is-loading");
      });
    });
  }

  // Handle saving
  container.querySelector("#save-button").addEventListener("click", (ev) => {
    if (!validate(container, routine)) {
      console.log("Validation failed!");
      return;
    }

    console.log("Saving routine");
    container.querySelector("#save-button").classList.add("is-loading");
    patchPartnerWebRoutine(routine.getUuid(), routine)
    .then(() => {
      navigateTo("view_routine", {routine_uuid: routine.getUuid()});
    })
    .catch((err) => {
      container.querySelector("#save-errors").appendChild(ErrorNotification(err));
    })
    .finally(() => {
      container.querySelector("#save-button").classList.remove("is-loading");
    })
  });


  // Handle cancel
  container.querySelector("#cancel-button").addEventListener("click", (ev) => {
    navigateTo("partner_manager");
  });
}
