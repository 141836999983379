import template from './index.html';
import orderRow from './order_row';

import localize from 'localize';
import { navigateTo } from 'app';

import { getPartnerWebOrders, getPartnerWebOrder } from 'api/tmyAdminV1';

import ErrorNotification from 'components/notification/error';

export default function Setup() {
  const container = document.createElement("div");
  container.innerHTML = template({
    t: localize({
      en: {
        title: "Orders (created through Partner Web)",
        loading: "Loading",
        filter: {
          pending: "Pending",
          invoiced: "Invoiced",
          payed: "Payed",
          free: "Free"
        },
        header: {
          order: "Order",
          code: "Code",
          activation: "Activation",
          patient_ident: "Patient ID",
          clinic: "Clinic",
          payer: "Payer",
          product: "Product",
          invoice_no: "Invoice no"
        }
      },
      sv: {
        title: "Beställningar (skapade genom Partner Web)",
        loading: "Laddar",
        filter: {
          pending: "Ej hanterade",
          invoiced: "Fakturerade",
          payed: "Betalade",
          free: "Gratis"
        },
        header: {
          order: "Beställning",
          code: "Kod",
          activation: "Aktivering",
          patient_ident: "Patient ID",
          clinic: "Klinik",
          payer: "Betalare",
          product: "Produkt",
          invoice_no: "Fakturanr"
        }
      }
    })
  });

  const orderFilter = getOrderFilter();

  // Setup filter buttons
  const filterButtons = container.querySelectorAll(".filters .button");
  filterButtons.forEach((button) => {
    const buttonFilter = button.getAttribute("data-filter");
    if (buttonFilter == orderFilter) {
      button.classList.add('is-selected');
      button.classList.add('is-info');
    }

    button.href = `?page=partner_orders&filter=${buttonFilter}`;
    button.addEventListener("click", (ev) => {
      if (ev.metaKey) { return; }
      ev.preventDefault();

      navigateTo("partner_orders", { filter: buttonFilter })
    });
  });

  // Load data
  const progressBar = container.querySelector("progress");
  const orderContainer = container.querySelector("tbody.orders");

  progressBar.style.display = "block";
  getPartnerWebOrders().then((orders) => {
    progressBar.style.display = "none";

    Array.from(orders)
      .sort((a, b) => b.getCreatedAt() - a.getCreatedAt())
      .forEach((order) => {
        let orderType;
        if (order.getInvoicedAt() == 0.0) {
          orderType = 'pending';
        } else if (order.getInvoiceNo() == -1) {
          orderType = 'free';
        } else if (order.getInvoicePayedAt() == 0.0) {
          orderType = 'invoiced';
        } else {
          orderType = 'payed';
        }

        if (orderType != orderFilter) {
          return;
        }

        orderContainer.appendChild(orderRow(
          order,
          (err) => container.querySelector(".error-container").replaceChildren(ErrorNotification(err, true))
        ));
      });
  });

  return container;
}

function getOrderFilter() {
  const allowedFilters = ['pending', 'invoiced', 'payed', 'free'];

  let filterParam = require('querystring').parse(window.location.search.substring(1)).filter;
  if (allowedFilters.indexOf(filterParam) !== -1) {
    return filterParam;
  } else {
    return allowedFilters[0];
  }
}
