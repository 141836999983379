import template from './index.html';

import localize from 'localize';
import { navigateTo } from 'app';

import { getPartnerWebPayer, patchPartnerWebPayer, postPartnerWebPayer, getPartnerWebRoutines } from 'api/tmyAdminV1';
import ErrorNotification from 'components/notification/error';

import uuidv4 from 'uuid';
import { PartnerWebPayer } from 'tummylab-protobuf/js/api.tmy.admin.v1/partner_web_payer_pb';

const strings = {
  en: {
    title: "Payer",
    name: "Name",
    save: "Save & reload",
    create: "Create payer",
    cancel: "Cancel & go back",
    patient_id_label: "What is used to identify patients?",
    personnummer: "Personnummer",
    phone_number: "Phone number",
    custom_id: "Custom ID",
    custom_patient_id_regex: "Regex for validating custom patient ID (leave blank for any)",
    custom_patient_id_title: "Title to show for custom patient ID",
    requires_confirmation: "Requires confirmation before order",
    pre_order_information: "Information to show before ordering (leave blank to hide)",
    post_order_information: "Information to show after order is complete (leave blank to hide)",
    disclaimer: "All changes are immediately visible for our partners after they have been saved.",
    logo_label: "Logo",
    no_logo: "No logo",
    custom_logo: "Specify logo URL",
    custom_logo_url: "Logo URL",
    routine_label: "Routine",
    no_routine: "« No routine »",
  },
  sv: {
    title: "Betalare",
    name: "Namn",
    save: "Spara & ladda om",
    create: "Skapa betalare",
    cancel: "Avbryt & gå tillbaka",
    patient_id_label: "Vad används för att identifiera patienter?",
    personnummer: "Personnummer",
    phone_number: "Mobilnummer",
    custom_id: "Specialanpassad ID",
    custom_patient_id_regex: "Regex för validering av specialanpassad patient-ID (lämna tomt för att acceptera allt)",
    custom_patient_id_title: "Titel som visas för specialanpassad patient-ID",
    requires_confirmation: "Bekräftelse krävs innan beställning",
    pre_order_information: "Information som visas innan beställning (lämna tomt för att dölja)",
    post_order_information: "Information som visas efter en lyckad beställning (lämna tomt för att dölja)",
    disclaimer: "Alla ändringar blir direkt synliga för våra partners efter att de sparats.",
    logo_label: "Logotyp",
    no_logo: "Ingen logotyp",
    custom_logo: "Ange URL till logotyp",
    custom_logo_url: "URL till logotyp",
    routine_label: "Rutin",
    no_routine: "« Ingen rutin »",
  }
};

export default function Setup() {
  const container = document.createElement("div") ;
  const createNewPayer = require('querystring').parse(window.location.search.substring(1)).create_new || null;
  if (createNewPayer !== null) {
    const newPayer = new PartnerWebPayer();
    newPayer.setUuid(uuidv4());
    const progress = document.createElement("progress");
    progress.classList.add("progress", "is-info");
    progress.setAttribute("max", "100");

    container.appendChild(progress);
    getPartnerWebRoutines()
    .then((routines) => {
      populatePayer(container, newPayer, routines, true);
    })
    .catch((err) => {
      container.appendChild(ErrorNotification(err));
    })
    .finally(() => {
      progress.remove();
    });
    return container;
  }

  const payerUUID = require('querystring').parse(window.location.search.substring(1)).payer_uuid || null;
  if (payerUUID === null) {
    container.appendChild(ErrorNotification(localize({ en: "No payer specified", sv: "Ingen betalare angiven"}), false));
    return container;
  }

  const progress = document.createElement("progress");
  progress.classList.add("progress", "is-info");
  progress.setAttribute("max", "100");

  container.appendChild(progress);

  Promise.all([getPartnerWebPayer(payerUUID), getPartnerWebRoutines()])
  .then(([payer, routines]) => {
    populatePayer(container, payer, routines);
  })
  .catch((err) => {
    container.appendChild(ErrorNotification(err));
  })
  .finally(() => {
    progress.remove();
  });

  return container;
}

function populatePayer(container, payer, routines, createNew = false) {
  container.innerHTML = template({
    t: localize(strings),
    uuid: payer.getUuid(),
    name: payer.getName(),
    custom_logo_url: payer.getLogoUrl(),
    patient_id_regex: payer.getCustomPatientIdRegex(),
    patient_id_title: payer.getCustomPatientIdTitle(),
    pre_order_information: payer.getPreOrderInformation(),
    post_order_information: payer.getPostOrderInformation(),
  });

  if (createNew) {
    container.querySelector("h1.title").innerText = localize({en: "Create Payer", sv: "Skapa Betalare"});
  }

  if (payer.getUsesPhoneNumberAsPatientId()) {
    container.querySelector("#phone-as-id").checked = true;
  }

  if (payer.getUsesCustomPatientId()) {
    container.querySelector("#custom-patient-id").checked = true;
    container.querySelector("#custom-patient-id-container").classList.remove("is-hidden");
  }

  if (payer.getRequiresConfirmationBeforeOrder()) {
    container.querySelector("#requires-confirmation").checked = true;
  }

  if (payer.getHasLogoUrl()) {
    container.querySelector("#custom-logo").checked = true;
    container.querySelector("#use-custom-logo-field").classList.remove("is-hidden");
  }

  const routineSelector = container.querySelector("#routine-selector");
  const routineMap = {};
  routines.forEach((routine) => {
    routineMap[routine.getUuid()] = routine;

    const option = document.createElement("option");
    option.setAttribute("value", routine.getUuid());
    option.innerText = routine.getInternalName();
    routineSelector.appendChild(option);
  });

  // These rules must be kept in sync with backend logic and edit_payer_relation
  function configureForRoutine(routine) {
    const requiresConfirmationField = container.querySelector("#requires-confirmation");
    if (routine) {
      requiresConfirmationField.disabled = true;
      requiresConfirmationField.checked = routine.getRequiresInquiryConfirmationBeforeOrder();
    } else {
      requiresConfirmationField.disabled = false;
      requiresConfirmationField.checked = payer.getRequiresConfirmationBeforeOrder();
    }

    const preOrderInfoField = container.querySelector("#pre-order-information");
    if (routine) {
      preOrderInfoField.disabled = true;
      preOrderInfoField.value = routine.getPreOrderInformation()
    } else {
      preOrderInfoField.disabled = false;
      preOrderInfoField.value = payer.getPreOrderInformation();
    }

    const postOrderInfoField = container.querySelector("#post-order-information");
    if (routine) {
      postOrderInfoField.disabled = true;
      postOrderInfoField.value = routine.getShouldInformAfterOrder() ? localize({
        sv: `Glöm ej att meddela betalaren (${routine.getShouldInformAfterOrder()})`,
        en: `Don't forget to inform the payer (${routine.getShouldInformAfterOrder()})`
      }) : '';
    } else {
      postOrderInfoField.disabled = false;
      postOrderInfoField.value = payer.getPostOrderInformation();
    }
  }

  if (payer.getHasRoutine()) {
    const routine = routineMap[payer.getRoutineUuid()];
    if (routine) {
      routineSelector.value = payer.getRoutineUuid();
    }
    configureForRoutine(routine);
  } else {
    configureForRoutine(null);
  }
  routineSelector.addEventListener("change", (ev) => {
    console.log("Selected routine", ev.target.value);
    if (ev.target.value == "default") {
      payer.setHasRoutine(false);
      payer.setRoutineUuid("");
      configureForRoutine(null);
    } else {
      payer.setHasRoutine(true);
      payer.setRoutineUuid(ev.target.value);
      configureForRoutine(routineMap[ev.target.value]);
    }
  });

  function selectPatientID(patientID) {
    let usePhone = false;
    let useCustom = false;

    switch (patientID) {
      case "phone":
        usePhone = true;
        break;
      case "custom":
        useCustom = true;
        break;
    }

    if (useCustom) {
      container.querySelector("#custom-patient-id-container").classList.remove("is-hidden");
    } else {
      container.querySelector("#custom-patient-id-container").classList.add("is-hidden");
    }
    payer.setUsesPhoneNumberAsPatientId(usePhone);
    payer.setUsesCustomPatientId(useCustom);
  }

  container.querySelectorAll(".radio-id").forEach((el) => {
    el.addEventListener("change", (ev) => {
      selectPatientID(ev.target.value);
    });
  });

  function selectLogoOption(logoOption) {
    if (logoOption == "custom") {
      container.querySelector("#use-custom-logo-field").classList.remove("is-hidden");
      payer.setHasLogoUrl(true);
    } else {
      container.querySelector("#use-custom-logo-field").classList.add("is-hidden");
      container.querySelector("#custom-logo-url").classList.remove("is-danger");
      payer.setHasLogoUrl(false);
    }
  }

  container.querySelectorAll(".radio-logo").forEach((el) => {
    el.addEventListener("change", (ev) => {
      selectLogoOption(ev.target.value);
    })
  });

  container.querySelector("#custom-logo-url").addEventListener("change", (ev) => {
    payer.setLogoUrl(ev.target.value);
    if (ev.target.value == "") {
      ev.target.classList.add("is-danger");
      container.querySelector("#custom-logo-img").setAttribute("src", "");
    } else {
      ev.target.classList.remove("is-danger");
      container.querySelector("#custom-logo-img").setAttribute("src", ev.target.value);
    }
  });

  container.querySelector("#name-input").addEventListener("change", (ev) => {
    if (ev.target.value == "") {
      ev.target.classList.add("is-danger");
      container.querySelector("#save-button").classList.add("is-disabled");
      container.querySelector("#save-button").setAttribute("disabled", "disabled");
    } else {
      payer.setName(ev.target.value);
      ev.target.classList.remove("is-danger");
      container.querySelector("#save-button").classList.remove("is-disabled");
      container.querySelector("#save-button").removeAttribute("disabled");
    }
  });

  container.querySelector("#custom-patient-id-regex").addEventListener("change", (ev) => {
    payer.setCustomPatientIdRegex(ev.target.value);
  });

  container.querySelector("#custom-patient-id-title").addEventListener("change", (ev) => {
    payer.setCustomPatientIdTitle(ev.target.value);
  });

  container.querySelector("#requires-confirmation").addEventListener("change", (ev) => {
    payer.setRequiresConfirmationBeforeOrder(ev.target.checked);
  });

  container.querySelector("#pre-order-information").addEventListener("change", (ev) => {
    const trimmedInfo = ev.target.value.trim();
    payer.setHasPreOrderInformation(trimmedInfo == "");
    if (trimmedInfo != "") {
      payer.setPreOrderInformation(trimmedInfo);
    }
  });

  container.querySelector("#post-order-information").addEventListener("change", (ev) => {
    const trimmedInfo = ev.target.value.trim();
    payer.setHasPostOrderInformation(trimmedInfo == "");
    if (trimmedInfo != "") {
      payer.setPostOrderInformation(trimmedInfo);
    }
  });

  if (createNew) {
    container.querySelectorAll(".toggle-visibility-in-create").forEach((el) => { el.classList.toggle("is-hidden") });
    container.querySelector("#create-button").addEventListener("click", (ev) => {
      const payerName = container.querySelector("#name-input").value;
      if (payerName == "") {
        console.log("Name can't be empty");
        return;
      }
      console.log("Creating payer");
      ev.target.classList.add("is-loading");
      postPartnerWebPayer(payer)
      .then(() => {
        navigateTo("view_payer", {payer_uuid: payer.getUuid()});
      })
      .catch((err) => {
        container.querySelector("#save-errors").appendChild(ErrorNotification(err));
      })
      .finally(() => {
        ev.target.classList.remove("is-loading");
      });
    });
  }

  container.querySelector("#save-button").addEventListener("click", (ev) => {
    const newName = container.querySelector("#name-input").value;
    if (newName == "") {
      console.log("Name can't be empty");
      return;
    }
    if (payer.getLogoUrl() == "") {
      payer.setHasLogoUrl(false);
    }
    console.log("Saving payer");
    container.querySelector("#save-button").classList.add("is-loading");
    patchPartnerWebPayer(payer.getUuid(), payer)
    .then(() => {
        navigateTo("view_payer", {payer_uuid: payer.getUuid()});
    })
    .catch((err) => {
      container.querySelector("#save-errors").appendChild(ErrorNotification(err));
    })
    .finally(() => {
      container.querySelector("#save-button").classList.remove("is-loading");
    })
  });

  container.querySelector("#cancel-button").addEventListener("click", (ev) => {
    navigateTo("partner_manager");
  });
}
