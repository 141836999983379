import template from './index.html';
import itemTemplate from './item_template.html';

import localize from 'localize';
import { navigateTo } from 'app';
import * as comp from 'comparator';

import { getPartnerWebClinics, getPartnerWebPayers, getPartnerWebProducts, getPartnerWebRoutines } from 'api/tmyAdminV1';
import ErrorNotification from 'components/notification/error';

const strings = {
  en: {
    title: "Partner Manager",
    loading: "Loading",
    clinics_title: "Clinics",
    payers_title: "Payers",
    products_title: "Products",
    routines_title: "Routines",
    new: "Add new",
  },
  sv: {
    title: "Partnerhantering",
    loading: "Laddar",
    clinics_title: "Kliniker",
    payers_title: "Betalare",
    products_title: "Produkter",
    routines_title: "Rutiner",
    new: "Skapa ny",
  }
};

export default function Setup() {
  const container = document.createElement("div") ;
  container.innerHTML = template({
    t: localize(strings),
  });

  const progressClinics = container.querySelector("#progress-clinics");
  const progressPayers = container.querySelector("#progress-payers");
  const progressProducts = container.querySelector("#progress-products");
  const progressRoutines = container.querySelector("#progress-routines");

  const clinicsContainer = container.querySelector("#clinics-container");
  const payersContainer = container.querySelector("#payers-container");
  const productsContainer = container.querySelector("#products-container");
  const routinesContainer = container.querySelector("#routines-container");

  getPartnerWebClinics()
  .then((clinics) => {
    const ul = document.createElement("ul");
    clinics.sort(comp.ascending(el => el.getName())).forEach((clinic) => {
      const li = document.createElement("li");
      li.innerHTML = itemTemplate({
        title: clinic.getName(),
        url: `?page=view_clinic&clinic_uuid=${clinic.getUuid()}`
      });
      li.querySelector("a").addEventListener("click", (ev) => {
        if (ev.metaKey) { return; }
        ev.preventDefault();
        navigateTo("view_clinic", { clinic_uuid: clinic.getUuid() });
      });
      ul.appendChild(li);
    });
    clinicsContainer.appendChild(ul);
  })
  .catch((err) => {
    clinicsContainer.appendChild(ErrorNotification(err));
  })
  .finally(() => {
    progressClinics.remove();
  });

  getPartnerWebPayers()
  .then((payers) => {
    const ul = document.createElement("ul");
    payers.sort(comp.ascending(el => el.getName())).forEach((payer) => {
      const li = document.createElement("li");
      li.innerHTML = itemTemplate({
        title: payer.getName(),
        url: `?page=view_payer&payer_uuid=${payer.getUuid()}`
      });
      li.querySelector("a").addEventListener("click", (ev) => {
        if (ev.metaKey) { return; }
        ev.preventDefault();
        navigateTo("view_payer", { payer_uuid: payer.getUuid() });
      });
      ul.appendChild(li);
    });
    payersContainer.appendChild(ul);
  })
  .catch((err) => {
    payersContainer.appendChild(ErrorNotification(err));
  })
  .finally(() => {
    progressPayers.remove();
  });

  getPartnerWebProducts()
  .then((products) => {
    const ul = document.createElement("ul");
    products.sort(comp.ascending(el => el.getName())).forEach((product) => {
      const li = document.createElement("li");
      li.innerHTML = itemTemplate({
        title: product.getName(),
        url: `?page=view_product&product_uuid=${product.getUuid()}`
      });
      li.querySelector("a").addEventListener("click", (ev) => {
        if (ev.metaKey) { return; }
        ev.preventDefault();
        navigateTo("view_product", { product_uuid: product.getUuid() });
      })
      ul.appendChild(li);
    });
    productsContainer.appendChild(ul);
  })
  .catch((err) => {
    productsContainer.appendChild(ErrorNotification(err));
  })
  .finally(() => {
    progressProducts.remove();
  });

  getPartnerWebRoutines()
  .then((routines) => {
    const ul = document.createElement("ul");
    routines.sort(comp.ascending(el => el.getInternalName())).forEach((routine) => {
      const li = document.createElement("li");
      li.innerHTML = itemTemplate({
        title: routine.getInternalName(),
        url: `?page=view_routine&routine_uuid=${routine.getUuid()}`
      })
      li.querySelector("a").addEventListener("click", (ev) => {
        if (ev.metaKey) { return; }
        ev.preventDefault();
        navigateTo("view_routine", { routine_uuid: routine.getUuid() });
      })
      ul.appendChild(li);
    });
    routinesContainer.appendChild(ul);
  })
  .catch((err) => {
    routinesContainer.appendChild(ErrorNotification(err));
  })
  .finally(() => {
    progressRoutines.remove();
  });


  const addClinicLink = container.querySelector("#add-clinic");
  addClinicLink.href = "?page=view_clinic&create_new=true";
  addClinicLink.addEventListener("click", (ev) => {
    if (ev.metaKey) { return; }
    ev.preventDefault();
    navigateTo("view_clinic", { create_new: true });
  });
  const addPayerLink = container.querySelector("#add-payer");
  addPayerLink.href = "?page=view_payer&create_new=true";
  addPayerLink.addEventListener("click", (ev) => {
    if (ev.metaKey) { return; }
    ev.preventDefault();
    navigateTo("view_payer", { create_new: true });
  });
  const addProductLink = container.querySelector("#add-product");
  addProductLink.href = "?page=view_product&create_new=true";
  addProductLink.addEventListener("click", (ev) => {
    if (ev.metaKey) { return; }
    ev.preventDefault();
    navigateTo("view_product", { create_new: true });
  });
  container.querySelector("#add-routine").addEventListener("click", (ev) => {
    console.log("Create new routine");
    navigateTo("view_routine", { create_new: true });
  })

  return container;
}
