import template from './index.html';

export default function Setup(type, message, deletable) {
  const container = document.createElement("div");
  container.innerHTML = template({
    type: type, message: message
  });

  const button = container.querySelector("button");
  if (deletable) {
    container.querySelector("button").addEventListener("click", (ev) => {
      container.remove();
    });
  } else {
    button.remove();
  }

  return container;
}
