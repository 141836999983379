import "core-js/stable";
import "regenerator-runtime/runtime";
import 'whatwg-fetch';

import '@fortawesome/fontawesome-free/js/all.js';
import './index.scss';

import './extensions.js';

import * as authToken from 'authToken';
import localize from 'localize';

import PageLogin from 'pages/login';
import PageIndex from 'pages/index';
import PageUserProgressDashboard from 'pages/user_progress_dashboard';
import PageAppInstallStats from 'pages/app_install_stats';
import PageExtendableSubscriptions from 'pages/extendable_subscriptions';
import PageSurveyStats from 'pages/survey_stats';
import PageIbsSssStats from 'pages/ibs_sss';
import PagePartnerManager from 'pages/partner_manager';
import PagePartnerOrders from 'pages/partner_orders';
import PageViewClinic from 'pages/view_clinic';
import PageViewPayer from 'pages/view_payer';
import PageViewProduct from 'pages/view_product';
import PageViewRoutine from 'pages/view_routine';

import ErrorNotification from 'components/notification/error';

export function navigateTo(page, extra = {}) {
  const container = document.querySelector(".page-container");
  refreshContent(container, page, false, extra);
}

function refreshContent(container, page, replaceState = false, extra = {}) {
  if (replaceState) {
    window.history.replaceState({page: page}, document.title, document.location);
  } else {
    let url = "?page=" + page;
    Object.keys(extra).forEach((key) => {
      url += "&" + key + "=" + extra[key];
    });
    window.history.pushState({page: page}, document.title, url);
  }

  container.removeAllChildren();

  document.querySelector(".navbar-menu").querySelectorAll(".navbar-item").forEach((navItem) => {
    if (authToken.get()) {
      navItem.classList.remove("is-hidden");
    } else {
      navItem.classList.add("is-hidden");
    }
  });

  const logoutMenuItem = document.querySelector(".navbar-item[data-page=_logout]");
  if (!authToken.get()) {
    logoutMenuItem.classList.add("is-hidden");
  } else {
    logoutMenuItem.classList.remove("is-hidden");
  }

  let currentAuthToken = null;
  try {
    currentAuthToken = authToken.getAndThrowIfExpired();
  } catch(err) {
    authToken.set(null);
    container.replaceChildren(ErrorNotification(err, true));
  }

  if (!currentAuthToken) {
    container.replaceChildren(PageLogin((newAuthToken) => {
      authToken.set(newAuthToken);
      refreshContent(container, page, true);
    }));

    return;
  }

  switch (page) {
    case "user_progress_dashboard":
      container.replaceChildren(PageUserProgressDashboard());
    break;
    case "app_install_stats":
      container.replaceChildren(PageAppInstallStats());
    break;
    case "extendable_subscriptions":
      container.replaceChildren(PageExtendableSubscriptions())
    break;
    case "survey_stats":
      container.replaceChildren(PageSurveyStats());
      break;
    case "ibs_sss":
      container.replaceChildren(PageIbsSssStats());
      break;
    case "partner_orders":
      container.replaceChildren(PagePartnerOrders());
      break;
    case "partner_manager":
      container.replaceChildren(PagePartnerManager());
      break;
    case "view_clinic":
      container.replaceChildren(PageViewClinic());
      break;
    case "view_payer":
      container.replaceChildren(PageViewPayer());
      break;
    case "view_product":
      container.replaceChildren(PageViewProduct());
      break;
    case "view_routine":
      container.replaceChildren(PageViewRoutine());
      break;
    case "index":
    default:
      container.replaceChildren(PageIndex());
    break;
  }
}

const buildMenuItem = function(pageId, title) {
  const el = document.createElement("a");
  el.className = "navbar-item";
  el.setAttribute("data-page", pageId);
  el.appendChild(document.createTextNode(title));
  if (pageId != "_logout") {
    el.href = `?page=${pageId}`
  }
  return el;
}

document.addEventListener("DOMContentLoaded", () => {
  const navBarStart = document.querySelector("#navMenu .navbar-start");
  navBarStart.appendChild(buildMenuItem("user_progress_dashboard", localize({
    sv: 'Aktiva Användare',
    en: 'Active Users'
  })));
  navBarStart.appendChild(buildMenuItem("app_install_stats", localize({
    sv: 'Appinstallationer',
    en: 'App Installs'
  })));
  navBarStart.appendChild(buildMenuItem("partner_orders", localize({
    sv: 'Beställningar',
    en: 'Orders'
  })));
  navBarStart.appendChild(buildMenuItem("extendable_subscriptions", localize({
    sv: 'Prenumerationer',
    en: 'Subscriptions'
  })));
  navBarStart.appendChild(buildMenuItem("survey_stats", localize({
    sv: 'Enkätstatistik',
    en: 'Survey Statistics'
  })));
  navBarStart.appendChild(buildMenuItem("ibs_sss", localize({
    sv: 'IBS-SSS Poäng',
    en: 'IBS-SSS Score'
  })));
  navBarStart.appendChild(buildMenuItem("partner_manager", localize({
    sv: 'Partnerhantering',
    en: 'Partner Manager'
  })));

  const navBarEnd = document.querySelector("#navMenu .navbar-end");
  navBarEnd.appendChild(buildMenuItem("_logout", localize({
    sv: 'Logga ut',
    en: 'Logout'
  })));

  const initialPage = require('querystring').parse(window.location.search.substring(1)).page || "index";
  const container = document.querySelector(".page-container");
  refreshContent(container, initialPage, true);

  window.addEventListener("popstate", (ev) => {
    refreshContent(container, event.state.page, true);
  });

  document.querySelectorAll('.navbar-item').forEach((el) => {
    el.addEventListener("click", (ev) => {
      const page = el.getAttribute("data-page");
      if (page != "_logout" && ev.metaKey) { return; }

      ev.preventDefault();

      if (page === "_logout") {
        authToken.set(null);
        refreshContent(container, "index");
      } else {
        refreshContent(container, page);
      }
    });
  });

  const navbarBurger = document.querySelector('.navbar-burger');
  navbarBurger.addEventListener('click', function () {
    navbarBurger
      .classList
      .toggle('is-active');
    document
      .getElementById(navbarBurger.getAttribute("data-target"))
      .classList
      .toggle('is-active');

  });
});
