import template from './index.html';
import templateScoreContainer from './score_container.html';
import templateScorePairsContainer from './score_pairs_container.html';

import localize from 'localize';

import { getIbsSssStatistics } from 'api/tmyAdminV1';

import ErrorNotification from 'components/notification/error';
import { renderGenericNumberStatistics } from 'components/survey_question_statistic/render_util';

import Chart from 'chart.js';

const strings = {
  en: {
    title: "IBS–SSS Statistics",
    loading: "Loading",
    initial_score: "Initial score",
    final_score: "Final score",
    score_change: "Score change",
    score_pairs: "Before/After movement",
  },
  sv: {
    title: "IBS–SSS Statistik",
    loading: "Laddar",
    initial_score: "Initial poäng",
    final_score: "Slutlig poäng",
    score_change: "Förändring av poäng",
    score_pairs: "Före/efter-rörelse",
  }
}

const surveyPairs = {
  "Standard": {
    initial: "90fc7e98-4c99-5ee1-9beb-ea2db66bc350",
    final: "2c8d15fb-f62b-530f-9830-41ebfe3de50d"
  },
  "Wästerläkarna": {
    initial: "3f90b4e6-d89d-51d0-bb97-7108f3449b4f",
    final: "3153d76e-7982-5706-86e5-05028fc15438"
  }
}

const TRANSPARENT_COLOR = "#00000000";
const INITIAL_SCORE_COLOR = "#000";
const FINAL_SCORE_COLOR = "#AACFF4";

export default function Setup() {
  const container = document.createElement("div") ;
  container.innerHTML = template({ t: localize(strings) });

  let initialSurveyPair = Object.keys(surveyPairs)[0];

  const paramInitialSurveyUUID = require('querystring').parse(window.location.search.substring(1)).initial_survey_uuid;
  const paramFinalSurveyUUID = require('querystring').parse(window.location.search.substring(1)).final_survey_uuid;
  if (paramInitialSurveyUUID && paramFinalSurveyUUID) {
    initialSurveyPair = "URL";
    surveyPairs[initialSurveyPair] = {
      initial: paramInitialSurveyUUID,
      final: paramFinalSurveyUUID
    }
  }
  const statsContainer = container.querySelector("#stats-container");
  const errorContainer = container.querySelector("#error-container");
  const surveyPairSelect = container.querySelector("select");

  for (let surveyPair in surveyPairs) {
    const option = document.createElement("option");
    option.text = surveyPair;
    option.value = surveyPair;
    option.defaultSelected = (surveyPair == initialSurveyPair);
    surveyPairSelect.appendChild(option);
  }

  function renderTemplateScoreContainerIntoStatsContainer(templateParams) {
    const fragment = document.createElement("div");
    fragment.innerHTML = templateScoreContainer(templateParams);
    fragment.childNodes.forEach((child) => {
      statsContainer.appendChild(child);
    });
    return statsContainer.lastChild;
  }

  function renderTemplateScorePairsContainerIntoStatsContainer(templateParams, container) {
    const fragment = document.createElement("div");
    fragment.innerHTML = templateScorePairsContainer(templateParams);
    fragment.childNodes.forEach((child) => {
      statsContainer.appendChild(child);
    });
    return statsContainer.lastChild;
  }

  function renderForSurveyPair(surveyPair) {
    errorContainer.removeAllChildren();
    statsContainer.removeAllChildren();
    container.querySelector("progress").classList.remove("is-hidden");

    const { initial, final } = surveyPairs[surveyPair];
    getIbsSssStatistics(initial, final)
      .then((resp) => {
        renderTemplateScoreContainerIntoStatsContainer({t: localize(strings).initial_score});
        renderTemplateScoreContainerIntoStatsContainer({t: localize(strings).final_score})
        renderGenericNumberStatistics(
          renderTemplateScoreContainerIntoStatsContainer({t: {title: localize(strings).initial_score}}),
          resp.getScoreInitial(), 0, 500, 20
        );
        renderGenericNumberStatistics(
          renderTemplateScoreContainerIntoStatsContainer({t: {title: localize(strings).final_score}}),
          resp.getScoreFinal(), 0, 500, 20
        );
        renderGenericNumberStatistics(
          renderTemplateScoreContainerIntoStatsContainer({t: {title: localize(strings).score_change}}),
          resp.getScoreChange(), -500, 500, 40
        );
        renderScorePairs(
          renderTemplateScorePairsContainerIntoStatsContainer({
            t: localize(strings),
            color_initial: INITIAL_SCORE_COLOR,
            color_final: FINAL_SCORE_COLOR,
          }),
          resp.getScorePairsList()
        );
      })
      .catch((err) => {
        console.log("Failed to get IBS-SSS statistics:", err);
        errorContainer.appendChild(ErrorNotification(err));
      })
      .finally(() => {
        container.querySelector("progress").classList.add("is-hidden");
      });
  }

  surveyPairSelect.addEventListener("change", (ev) => {
    ev.preventDefault();

    renderForSurveyPair(surveyPairSelect.value);
  });

  renderForSurveyPair(initialSurveyPair);
  return container;
}

/**
 * Renders the difference in IBS-SSS score by showing "before" and "after"
 * points on the same x-coordinate. A line is drawn between the two points.
 */
function renderScorePairs(container, pairs) {
  const chartDatasets = [];
  pairs.forEach((pair, idx) => {
    const dataset = {
      data: [{
        x: idx + 1,
        y: pair.getInitialScore()
      }, {
        x: idx + 1,
        y: pair.getFinalScore()
      }],
      borderColor: 'black',
      borderWidth: 1,
      pointBackgroundColor: [TRANSPARENT_COLOR, FINAL_SCORE_COLOR],
      pointBorderColor: [INITIAL_SCORE_COLOR, FINAL_SCORE_COLOR],
      pointRadius: 5,
      pointHoverRadius: 5,
      fill: false,
      tension: 0,
      showLine: true
    };
    chartDatasets.push(dataset);
  });

  const canvas = container.querySelector("#ibs-sss-score-pairs");

  new Chart(canvas, {
    type: 'scatter',
    data: {
      datasets: chartDatasets
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: false,
      tooltips: {
        mode: 'dataset',
        intersect: false,
        axis: 'x',
        callbacks: {
          label: (item, data) => item.yLabel.toString(),
          footer: (items, data) => {
            return 'Δ ' + (items[1].yLabel - items[0].yLabel).toString();
          }
        }
      },
      scales: {
        xAxes: [{
          offset: true,
          ticks: {
            min: 0,                 // Leave one "slot" empty on the left of the data
            max: pairs.length + 1,  // and one on the right of the data. This is nicer visually
            stepSize: 1,
          },
          gridLines: {
            drawOnChartArea: false
          }
        }],
        yAxes: [{
          ticks: {
            min: 0,     // Minimum IBS-SSS score
            max: 500,   // Maximum IBS-SSS score
            stepSize: 20,
          },
          gridLines: {
            drawOnChartArea: true
          }
        }]
      }
    }
  });
}
