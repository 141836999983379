import template from './index.html';

import localize from 'localize';

import { getAppInstallStats } from 'api/tmyAdminV1';

import Chart from 'chart.js';
import { Chart as  StatChart } from 'tummylab-protobuf/js/api.tmy.admin.v1/chart_pb';
import ErrorNotification from 'components/notification/error';

const colors = [
  '#F66C63',
  '#FFCC35',
  '#56A3AD',
  '#9F74C1',
  '#AAD0F5',
  '#FFA38F',
  '#75D494',
  '#BFA893',
  '#74D474',
  '#2E5FA1',
  '#FFFFFF',
  '#000000'
];

export default function Setup() {
  const container = document.createElement("div");
  container.innerHTML = template({
    t: localize({
      en: {
        title: "App Installs",
        loading: "Loading",
        param: {
          label: "Show stats for",
          last90: "Last 3 months",
          last30: "Last month",
          last14: "Last 2 weeks",
          last7: "Last week",
        }
      },
      sv: {
        title: "Appinstallationer",
        loading: "Laddar",
        param: {
          label: "Visa statistik från",
          last90: "Senaste 3 månaderna",
          last30: "Senaste månaden",
          last14: "Senaste 2 veckorna",
          last7: "Senaste veckan",
        }
      }
    })
  });

  const progressBar = container.querySelector("progress");

  function loadStats(sinceDays) {
    getAppInstallStats(sinceDays).then((stats) => {
      const statsContainer = container.querySelector(".installs");

      // Clear any previously loaded stats
      while(statsContainer.lastChild) {
        statsContainer.removeChild(statsContainer.lastChild);
      }

      progressBar.style.display = "none";

      if (stats.length > 0) {
        stats.filter(s => s.getDataList().length > 0).forEach((stat, idx) => {
          const canvas = document.createElement("canvas");
          const canvasName = "chart" + idx;
          canvas.setAttribute("id", canvasName);
          canvas.setAttribute("style", "margin: auto;")
          statsContainer.appendChild(canvas);

          createChartFromStat(canvas, stat);
        });
      } else {
        const para = document.createElement("p");
        para.textContent = localize({
          en: "There are no statistics to show...",
          sv: "Det finns ingen statistik att visa..."
        });
        statsContainer.appendChild(para);
      }
    })
    .catch((err) => {
      progressBar.style.display = "none";
      console.log("Failed to get app installs: ", err)
      const errorMsg = err.userDisplayableErrorMessage || localize({
        en: "Failed to get app install statistics.",
        sv: "Kunde ej hämta statistik för appinstallationer."
      });

      container.appendChild(ErrorNotification(errorMsg, true));
    });
  }

  loadStats(30);

  const filterSelector = container.querySelector("#days-param");
  filterSelector.addEventListener("change", () => {
    const days = filterSelector.value;
    progressBar.style.display = "block";
    loadStats(days);
  });

  return container;
}

/**
* Create a chartJS chart from a chart object given by the
* API.
*
* @param {*} ctx A HTML node to draw the chart in, should be a canvas
* @param {StatChart} stat The Chart object from the API
*/
function createChartFromStat(ctx, stat) {
  const data = {
    datasets: [{
      data: stat.getDataList(),
      backgroundColor: colors,
      label: "Count"
    }],
    labels: stat.getDataLabelsList()
  };

  const chartStyle = convertToChartJSStyle(stat.getStyle());

  let showLegend = false;
  let showAxes = true;
  if (chartStyle == 'pie' || chartStyle == 'doughnut') {
    showLegend = true;
    showAxes = false;
  }

  new Chart(ctx, {
    type: chartStyle,
    data: data,
    options: {
      legend: {
        display: showLegend,
        position: 'top'
      },
      title: {
        display: true,
        text: stat.getTitle(),
        fontSize: 22,
        fontColor: '#5A4660',
        fontFamily: 'Gotham Rounded SSm A, Gotham Rounded SSm B, Segoe UI, Roboto Fira Sans, Droid Sans, Helvetica, Arial, sans-serif',
      },
      aspectRatio: 1,
      responsive: false,
      scales: {
        xAxes: [{
          display: showAxes,
          ticks: {
            min: 0
          }
        }],
        yAxes: [{
          display: showAxes,
          ticks: {
            min: 0
          }
        }],
      }
    }
  });
}

/**
* Convert a Chart.Style enum from the protobuf definition
* to one that can be used with chartJS.
*
* @param {StatChart.Style} style The chart style to convert from
*/
function convertToChartJSStyle(style) {
  switch(style) {
    case StatChart.Style.PIE:
    return "pie";
    case StatChart.Style.DOUGHNUT:
    return "doughnut";
    case StatChart.Style.LINE:
    return "line";
    case StatChart.Style.BAR:
    return "bar";
    case StatChart.Style.BARHORIZONTAL:
    return "horizontalBar";
    default:
    throw "Unknown chart style " + style;
  }
}
