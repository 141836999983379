import Notification from './index.js';

import localize from 'localize';

export default function Setup(message, deletable) {
  return Notification(
    'danger', 
    message,
    deletable
  );
}
