import * as rh from './_responseHandlers';
import * as tmy from './_tmyHelpers';
import * as authToken from '../authToken';

import * as responses from 'tummylab-protobuf/js/api.tmy.users.v1/responses_pb';
import * as requests from 'tummylab-protobuf/js/api.tmy.users.v1/requests_pb';

const API_NAME = "tmy.users.v1";

export function postLogin(username, password, platformToken) {
  const msg = new requests.PostLogin();
  msg.setUsername(username);
  msg.setPassword(password);
  msg.setPlatformToken(platformToken);

  return tmy.post("/login", msg.serializeBinary(), {
    apiName: API_NAME,
    messageName: "API.TMY.Users.V1.Requests.PostLogin"
  })
    .then(rh.checkStatus)
    .then(rh.unpackProtobuf("API.TMY.Users.V1.Responses.PostLogin", responses.PostLogin));
}

export function getLogin() {
  return tmy.get("/login", {
    apiName: API_NAME,
    authToken: authToken.get()
  })
    .then(rh.checkStatus)
    .then(rh.unpackProtobuf("API.TMY.Users.V1.Responses.GetLogin", responses.GetLogin));
}