import template from './order_row.html';

import localize from 'localize';
import { navigateTo } from 'app';

import { updatePartnerWebOrderInvoiceRecord, updatePartnerWebOrderPaymentRecord } from 'api/tmyAdminV1';

export default function Setup(order, errorCallback) {
  const container = document.createElement("template");
  container.innerHTML = template({
    created_at: (new Date(order.getCreatedAt() * 1000)).toISODateString(),
    clinic_name: order.getClinicName(),
    product_name: order.getProductName(),
    payer_name: order.getPayerName(),
    user_activation_code: order.getUserActivationCode(),
    user_activation_code_claimed_at: order.getUserActivationCodeClaimedAt() != 0 ? (new Date(order.getUserActivationCodeClaimedAt() * 1000)).toISODateString() : undefined,
    invoice_no: order.getInvoiceNo() != 0 ? order.getInvoiceNo() : undefined,
    patient_ident: order.getPatientIdent(),

    has_form_invoiced: order.getInvoiceNo() == 0 && order.getUserActivationCodeClaimedAt() != 0,
    has_button_free: order.getInvoiceNo() == 0,

    has_button_payed: order.getInvoiceNo() > 0 && order.getInvoicePayedAt() == 0,
    has_button_uninvoiced: order.getInvoiceNo() > 0 && order.getInvoicePayedAt() == 0,

    has_button_unfree: order.getInvoiceNo() == -1,
    has_button_unpayed: order.getInvoiceNo() > 0 && order.getInvoicePayedAt() != 0,
    t: localize({
      en: {
        mark_invoiced: "Invoiced",
        mark_payed: "Payed",
        mark_free: "Free",
        mark_uninvoiced: "Not invoiced",
        mark_unpayed: "Not payed",
        mark_unfree: "Not free"
      },
      sv: {
        mark_invoiced: "Fakturerad",
        mark_payed: "Betalad",
        mark_free: "Gratis",
        mark_uninvoiced: "Ej fakturerad",
        mark_unpayed: "Ej betalad",
        mark_unfree: "Ej gratis"
      }
    })
  });
  const row = container.content.firstChild;

  function setupLink(anchorClass, page, param, uuid) {
    const link = row.querySelector(`a.${anchorClass}`);
    if (!link) { return; }

    link.href = `?page=${page}&${param}=${uuid}`;
    link.addEventListener("click", (ev) => {
      if (ev.metaKey) { return; }

      ev.preventDefault();

      navigateTo(page, {[param]: uuid});
    });
  }

  setupLink("user", "user_progress_dashboard", "user_activation_code", order.getUserActivationCode());

  function setupPartnerWebLink(type, uuid) {
    setupLink(type, `view_${type}`, `${type}_uuid`, uuid);
  }

  setupPartnerWebLink("clinic", order.getClinicUuid());
  setupPartnerWebLink("product", order.getProductUuid());
  setupPartnerWebLink("payer", order.getPayerUuid());

  const createButtonEventHandler = function(buttonSelector, promiseFactory) {
    const button = row.querySelector(buttonSelector)
    if (!button) { return; }

    button.addEventListener('click', (ev) => {
      ev.preventDefault();

      const promise = promiseFactory();
      if (!promise) { return; }

      return promise
        .then(() => { row.remove() })
        .catch((err) => {
          console.log(err);

          const errorMsg = err.userDisplayableErrorMessage || localize({
            en: "Failed to perform action.",
            sv: "Åtgärd misslyckades."
          });
          errorCallback(errorMsg);
        });
    });
  };

  createButtonEventHandler(".button-mark-invoiced", () => {
    const invoiceNo = row.querySelector("input.invoice-no").value;

    if (!RegExp('^[1-9][0-9]*$').test(invoiceNo)) {
      alert(localize({
        en: "Invoice no must be an integer > 0!",
        sv: "Fakturanr måste vara en siffra över 0!"
      }));

      return false;
    }

    return updatePartnerWebOrderInvoiceRecord(order.getUuid(), invoiceNo, new Date());
  });

  createButtonEventHandler(".button-mark-payed", () => {
    return updatePartnerWebOrderPaymentRecord(order.getUuid(), new Date());
  });

  createButtonEventHandler(".button-mark-free", () => {
    return updatePartnerWebOrderInvoiceRecord(order.getUuid(), -1, new Date())
      .then(() => updatePartnerWebOrderPaymentRecord(order.getUuid(), new Date()));
  });

  createButtonEventHandler(".button-mark-uninvoiced", () => {
    return updatePartnerWebOrderInvoiceRecord(order.getUuid(), undefined, undefined);
  });

  createButtonEventHandler(".button-mark-unpayed", () => {
    return updatePartnerWebOrderPaymentRecord(order.getUuid(), undefined);
  });

  createButtonEventHandler(".button-mark-unfree", () => {
    return updatePartnerWebOrderPaymentRecord(order.getUuid(), undefined)
      .then(() => updatePartnerWebOrderInvoiceRecord(order.getUuid(), undefined, undefined));
  });

  return row;
}
