import template from './index.html';
import loginInfoTemplate from './login_info.html';
import productItemTemplate from './product_item.html';
import payerItemTemplate from './payer_item.html';
import documentationItemTemplate from './documentation_item.html';

import localize from 'localize';
import { navigateTo } from 'app';
import * as comp from 'comparator';
import { Modal } from 'components/modal/modal';
import { FormModal } from 'components/modal/form_modal';
import EditPayerRelation from 'components/edit_payer_relation';

import { getPartnerWebClinic, patchPartnerWebClinic, postPartnerWebClinic, getPartnerWebProducts, getPartnerWebPayers, getPartnerWebRoutines } from 'api/tmyAdminV1';
import ErrorNotification from 'components/notification/error';

import uuidv4 from 'uuid';
import { PartnerWebClinic } from 'tummylab-protobuf/js/api.tmy.admin.v1/partner_web_clinic_pb';

const GENERATED_PASSWORD_LENGTH = 16;
const GENERATED_PASSWORD_CHARS = "abcdefghijklmnopqrstuvwxyz!@#$%-+<>ABCDEFGHIJKLMNOPQRSTUVXYZ1234567890";

const PASSWORD_MINIMUM_LENGTH = 8;

const strings = {
  en: {
    title: "Clinic",
    name: "Name",
    username: "Username",
    password: "Password",
    settings: "Settings",
    allow_hiding_payer: "Allow hiding payer in order form",
    save: "Save & reload",
    create: "Create clinic",
    cancel: "Cancel & go back",
    disclaimer: "All changes are immediately visible for our partners after they have been saved.",
    products: "Products",
    payers: "Payers",
    generate: "Generate",
    documentation: "Documentation",
    add_documentation: "Add new",
  },
  sv: {
    title: "Klinik",
    name: "Namn",
    username: "Användarnamn",
    password: "Lösenord",
    settings: "Inställningar",
    allow_hiding_payer: "Tillåt att betalare döljs i orderformulär",
    save: "Spara & ladda om",
    create: "Skapa klinik",
    cancel: "Avbryt & gå tillbaka",
    disclaimer: "Alla ändringar blir direkt synliga för våra partners efter att de sparats.",
    products: "Produkter",
    payers: "Betalare",
    generate: "Slumpa",
    documentation: "Dokumentation",
    add_documentation: "Lägg till",
  }
};

export default function Setup() {
  const container = document.createElement("div") ;
  const createNewClinic = require('querystring').parse(window.location.search.substring(1)).create_new || null;
  if (createNewClinic !== null) {
    const newClinic = new PartnerWebClinic();
    newClinic.setUuid(uuidv4());
    const progress = document.createElement("progress");
    progress.classList.add("progress", "is-info");
    progress.setAttribute("max", "100");

    container.appendChild(progress);
    getPartnerWebRoutines()
    .then((routines) => {
      populateClinic(container, newClinic, [], [], [], [], [], routines, true);
    })
    .catch((err) => {
      container.appendChild(ErrorNotification(err));
    })
    .finally(() => {
      progress.remove();
    });

    return container;
  }

  const clinicUUID = require('querystring').parse(window.location.search.substring(1)).clinic_uuid || null;
  if (clinicUUID === null) {
    container.appendChild(ErrorNotification(localize({ en: "No clinic specified", sv: "Ingen klinik angiven"}), false));
    return container;
  }

  const progress = document.createElement("progress");
  progress.classList.add("progress", "is-info");
  progress.setAttribute("max", "100");

  container.appendChild(progress);

  Promise.all([getPartnerWebClinic(clinicUUID), getPartnerWebProducts(), getPartnerWebPayers(), getPartnerWebRoutines()])
  .then(([resp, products, payers, routines]) => {
    populateClinic(container, resp.getClinic(), products, resp.getProductRelationsList(), payers, resp.getPayerRelationsList(), resp.getSupportDocumentsList(), routines);
  })
  .catch((err) => {
    container.appendChild(ErrorNotification(err));
  })
  .finally(() => {
    progress.remove();
  });

  return container;
}

function populateClinic(container, clinic, products, productRelations, payers, payerRelations, supportDocuments, routines, createNew = false) {
  container.innerHTML = template({
    t: localize(strings),
    uuid: clinic.getUuid(),
    name: clinic.getName(),
    username: clinic.getUsername(),
    allow_hiding_payer: clinic.getAllowHidingPayerSelectorInOrderForm()
  });

  container.querySelector("#name-input").addEventListener("input", (ev) => {
    clinic.setName(ev.target.value);
    if (ev.target.value == "") {
      ev.target.classList.add("is-danger");
    } else {
      ev.target.classList.remove("is-danger");
    }
  });

  container.querySelector("#allow-hiding-payer-input").addEventListener("input", (ev) => {
    clinic.setAllowHidingPayerSelectorInOrderForm(ev.target.checked);
  });

  if (createNew) {
    container.querySelector("h1.title").innerText = localize({en: "Create Clinic", sv: "Skapa Klinik"});
    setupCreationOfNewClinic(container, clinic);
  }

  // Populate the list of products associated with the clinic
  const productsContainer = container.querySelector("#products-list");
  let productUUIDs = [];
  productRelations.forEach((rel) => {
    productUUIDs.push(rel.getProductUuid());
  })
  products.sort(comp.ascending(obj => obj.getName())).forEach((product) => {
    const li = document.createElement("li");
    li.innerHTML = productItemTemplate({
      product_name: product.getName(),
      product_uuid: product.getUuid(),
    });

    const check = li.querySelector("input");
    if (productUUIDs.includes(product.getUuid())) {
      check.checked = true;
    }

    check.addEventListener("change", (ev) => {
      if (ev.target.checked) {
        console.log("Added product", product.getName());
        productUUIDs.push(ev.target.value);
      } else {
        console.log("Removed product", product.getName());
        productUUIDs = productUUIDs.filter((value) => { return value != ev.target.value} );
      }
    });
    productsContainer.appendChild(li);
  });

  // Populate the list of payers associated with the clinic
  const payersContainer = container.querySelector("#payers-list");
  let payersMapping = {};
  payerRelations.forEach((rel) => {
    payersMapping[rel.getPayerUuid()] = rel;
  });
  payers.sort(comp.ascending(obj => obj.getName())).forEach((payer) => {
    const li = document.createElement("li");
    li.classList.add("is-clearfix");
    li.style.paddingRight = "2rem";
    li.innerHTML = payerItemTemplate({
      edit_label: localize({en: "Edit", sv: "Redigera"}),
      payer_name: payer.getName(),
      payer_uuid: payer.getUuid(),
    });

    const check = li.querySelector("input");
    const editRelationButton = li.querySelector("a");
    const hasOverridesSpan = li.querySelector("span");
    editRelationButton.addEventListener("click", (ev) => {
      const relation = payersMapping[payer.getUuid()];
      const relationClone = clonePayerRelation(relation);
      const body = EditPayerRelation(relationClone, payer, routines);
      const modal = new Modal(container, {
        title: localize({en: "Edit payer relation", sv: "Redigera betalarens relation"}),
        positiveButton: localize({en: "Save relation", sv: "Spara relation"}),
        neutralButton: localize({en: "Cancel", sv: "Avbryt"}),
        onPositive: () => {
          modal.destroy();
          payersMapping[payer.getUuid()] = relationClone;

          // Indicate if this relation has any overridden values from the payer
          if (hasOverrides(relationClone)) {
            hasOverridesSpan.classList.remove("is-hidden");
          } else {
            hasOverridesSpan.classList.add("is-hidden");
          }
        },
      });
      modal.addContent(body);
      modal.show();
      ev.preventDefault();
    });

    check.addEventListener("change", (ev) => {
      if (ev.target.checked) {
        console.log("Added payer", payer.getName());
        editRelationButton.classList.remove("is-hidden");
        payersMapping[payer.getUuid()] = new PartnerWebClinic.PayerRelation();
        payersMapping[payer.getUuid()].setPayerUuid(payer.getUuid());
      } else {
        console.log("Removed payer", payer.getName());
        editRelationButton.classList.add("is-hidden");
        delete payersMapping[payer.getUuid()];
      }
    });

    if (Object.keys(payersMapping).includes(payer.getUuid())) {
      check.checked = true;
      editRelationButton.classList.remove("is-hidden");
      if (hasOverrides(payersMapping[payer.getUuid()])) {
        hasOverridesSpan.classList.remove("is-hidden");
      }
    }
    payersContainer.appendChild(li);
  });

  // Populate the list of documentation
  const documentationContainer = container.querySelector("#documentation-list");
  const documentsMapping = {};
  supportDocuments.forEach((doc) => {
    documentsMapping[doc.getTitle() + doc.getUrl()] = doc;
  });
  function refreshDocumentationList() {
    documentationContainer.removeAllChildren();
    Object.values(documentsMapping).sort(comp.ascending(obj => obj.getTitle())).forEach((doc) => {
      const li = document.createElement("li");
      li.style.paddingRight = "2rem";
      li.innerHTML = documentationItemTemplate({
        title: doc.getTitle(),
        url: doc.getUrl(),
        edit_label: localize({en: "Edit", sv: "Redigera"}),
      });
      const editButton = li.querySelector(".edit-doc-button");
      editButton.addEventListener("click", (ev) => {
        console.log("Edit support document");
        const entries = [
          {
            key: "title",
            label: "Title",
            prefill: doc.getTitle(),
          },
          {
            key: "url",
            label: "URL",
            prefill: doc.getUrl(),
          }
        ]
        const modal = new FormModal(container, entries, {
          title: localize({en: "Edit documentation", sv: "Redigera dokumentation"}),
          description: localize({en: "Edit the title or URL of the support document.", sv: "Redigera titeln eller URLen av supportdokumentet."}),
          positiveButton: localize({en: "Save change", sv: "Spara ändring"}),
          neutralButton: localize({en: "Cancel", sv: "Avbryt"}),
          negativeButton: localize({en: "Delete", sv: "Ta bort"}),
          onSubmit: (values) => {
            if (!values["url"].startsWith("https")) {
              alert("URL must be a https URL");
              return;
            }
            if (values["title"].trim() == "") {
              alert("Title can't be empty");
              return;
            }
            delete documentsMapping[doc.getTitle() + doc.getUrl()];
            doc.setTitle(values["title"]);
            doc.setUrl(values["url"]);
            documentsMapping[doc.getTitle() + doc.getUrl()] = doc;
            refreshDocumentationList();
          },
          onNegative: () => {
            delete documentsMapping[doc.getTitle() + doc.getUrl()];
            refreshDocumentationList();
          }
        });
        modal.show();
      });

      documentationContainer.appendChild(li);
    });
  }

  refreshDocumentationList();

  // Setup creation of new support documents
  container.querySelector("#add-documentation-button").addEventListener("click", (ev) => {
    console.log("Add new support document");
        const entries = [
          {
            key: "title",
            label: "Title",
          },
          {
            key: "url",
            label: "URL",
          }
        ]
        const modal = new FormModal(container, entries, {
          title: localize({en: "Create documentation", sv: "Skapa dokumentation"}),
          description: localize({en: "Create a new support document.", sv: "Skapa ett nytt supportdokumentet."}),
          positiveButton: localize({en: "Save change", sv: "Spara ändring"}),
          neutralButton: localize({en: "Cancel", sv: "Avbryt"}),
          onSubmit: (values) => {
            if (!values["url"].startsWith("https")) {
              alert("URL must be a https URL");
              return;
            }
            if (values["title"].trim() == "") {
              alert("Title can't be empty");
              return;
            }
            const doc = new PartnerWebClinic.SupportDocument();
            doc.setTitle(values["title"]);
            doc.setUrl(values["url"]);
            documentsMapping[doc.getTitle() + doc.getUrl()] = doc;
            refreshDocumentationList();
          },
        });
        modal.show();
  });

  // Setup save action
  container.querySelector("#save-button").addEventListener("click", (ev) => {
    if (clinic.getName() == "") {
      return;
    }
    console.log("Saving clinic");
    ev.target.classList.add("is-loading");
    const newProductRelations = [];
    productUUIDs.forEach((uuid) => {
      const relation = new PartnerWebClinic.ProductRelation();
      relation.setProductUuid(uuid);
      newProductRelations.push(relation);
    });
    const newPayerRelations = [];
    Object.values(payersMapping).forEach((rel) => {
      newPayerRelations.push(rel);
    });
    const newSupportDocuments = [];
    Object.values(documentsMapping).forEach((doc) => {
      newSupportDocuments.push(doc);
    });
    patchPartnerWebClinic(clinic.getUuid(), clinic, newProductRelations, newPayerRelations, newSupportDocuments)
    .then(() => {
      navigateTo("view_clinic", {clinic_uuid: clinic.getUuid()});
    })
    .catch((err) => {
      container.querySelector("#save-errors").appendChild(ErrorNotification(err));
    })
    .finally(() => {
      container.querySelector("#save-button").classList.remove("is-loading");
    })
  });

  // Setup cancel action
  container.querySelector("#cancel-button").addEventListener("click", (ev) => {
    navigateTo("partner_manager");
  });
}

function setupCreationOfNewClinic(container, clinic) {
  let password = null;
  container.querySelectorAll(".toggle-visibility-in-create").forEach((el) => { el.classList.toggle("is-hidden") });
  container.querySelector("#username-input").addEventListener("input", (ev) => {
    clinic.setUsername(ev.target.value);
    if (ev.target.value.trim() == "") {
      ev.target.classList.add("is-danger");
    } else {
      ev.target.classList.remove("is-danger");
    }
    updateLoginInfo(container, clinic.getUsername(), password);
  });
  const passwordWasChanged = () => {
    const el = container.querySelector("#password-input");
    password = el.value;

    if (password.trim() == "" || password.length < PASSWORD_MINIMUM_LENGTH) {
      el.classList.add("is-danger");
    } else {
      el.classList.remove("is-danger");
    }
    updateLoginInfo(container, clinic.getUsername(), password)
  }
  container.querySelector("#password-input").addEventListener("input", (ev) => {
    passwordWasChanged();
  });
  container.querySelector("#generate-password-button").addEventListener("click", (ev) => {
    let password = "";
    for (let x = 0; x < GENERATED_PASSWORD_LENGTH; x++) {
      const randomCharIndex = Math.floor(Math.random() * GENERATED_PASSWORD_CHARS.length);
      password += GENERATED_PASSWORD_CHARS.charAt(randomCharIndex);
    }
    container.querySelector("#password-input").value = password;
    passwordWasChanged();
  });
  container.querySelector("#create-button").addEventListener("click", (ev) => {
    if (password == null || password == "" || password.length < PASSWORD_MINIMUM_LENGTH) {
      container.querySelector("#password-input").classList.add("is-danger");
    }
    if (clinic.getUsername() == "") {
      container.querySelector("#username-input").classList.add("is-danger");
    }
    if (clinic.getName() == "") {
      container.querySelector("#name-input").classList.add("is-danger");
    }
    if (password == null || password == "" || password.length < PASSWORD_MINIMUM_LENGTH || clinic.getUsername() == "" || clinic.getName() == "") {
      return;
    }
    ev.target.classList.add("is-loading");
    postPartnerWebClinic(clinic, password)
    .then(() => {
      navigateTo("view_clinic", {clinic_uuid: clinic.getUuid()});
    })
    .catch((err) => {
      container.querySelector("#save-errors").appendChild(ErrorNotification(err));
    })
    .finally(() => {
      ev.target.classList.remove("is-loading");
    });
  });
}

function updateLoginInfo(container, username, password) {
  const loginInfoField = container.querySelector("#login-info");
  if (username === "" || username === null || password === "" || password === null) {
    loginInfoField.innerHTML = "";
    return;
  }

  const token = btoa(`{ "username": "${ username }", "password": "${ password }" }`);
  loginInfoField.innerHTML = loginInfoTemplate({
    username: username,
    password: password,
    url: `https://licens.tummylab.se/?page=authenticate&token=${encodeURIComponent(token)}`,
    t: localize({
      sv: {
        usernameLabel: "Användarnamn",
        passwordLabel: "Lösenord",
        urlLabel: "URL"
      },
      en: {
        usernameLabel: "Username",
        passwordLabel: "Password",
        urlLabel: "URL"
      }
    })
  });
}

function clonePayerRelation(relation) {
  const relationClone = new PartnerWebClinic.PayerRelation();

  relationClone.setPayerUuid(relation.getPayerUuid());
  relationClone.setHasRoutineOverride(relation.getHasRoutineOverride());
  relationClone.setRoutineUuid(relation.getRoutineUuid());
  relationClone.setHasRoutine(relation.getHasRoutine());

  return relationClone;
}

function hasOverrides(relation) {
  return relation.getHasRoutineOverride();
}
