import template from './index.html';

import localize from 'localize';
import { navigateTo } from 'app';

import { SurveyStatistics } from 'tummylab-protobuf/js/api.tmy.admin.v1/survey_statistics_pb';
import * as renderer from './render_util';

const strings = {
  en: {

  },
  sv: {

  }
};

export default function Setup(questionStat) {
  const container = document.createElement("div");
  const question = questionStat.getQuestion();
  let questionTitle = localize({en: "<no title>", sv: "<ingen titel>"});
  if (question.getTitle() != "") {
    questionTitle = question.getTitle();
  }
  container.innerHTML = template({
    t: localize(strings),
    title: questionTitle,
    uuid: question.getUuid(),
  });

  const chartContainer = container.querySelector(".chart-container");
  switch (questionStat.getStatisticCase()) {
    case SurveyStatistics.QuestionStatistics.StatisticCase.BRANCHING_STATISTIC:
      console.log("Branching question");
      renderer.renderBranchingStats(chartContainer, question, questionStat.getBranchingStatistic());
      break;
    case SurveyStatistics.QuestionStatistics.StatisticCase.NUMBER_AND_SLIDER_STATISTIC:
      console.log("Number/slider question");
      renderer.renderNumberAndSliderStats(chartContainer, question, questionStat.getNumberAndSliderStatistic());
      break;
    case SurveyStatistics.QuestionStatistics.StatisticCase.MULTI_CHOICE_STATISTIC:
      console.log("Multi-choice question");
      renderer.renderMultiChoiceStats(chartContainer, question, questionStat.getMultiChoiceStatistic());
      break;
    case SurveyStatistics.QuestionStatistics.StatisticCase.FREE_TEXT_STATISTIC:
      renderer.renderFreeTextStats(chartContainer, question, questionStat.getFreeTextStatistic());
      console.log("Free-text question");
      break;
    default:
      console.log("Unknown question type");
      throw "Unknown question type";
  }

  container.querySelector(".question-title").addEventListener("click", (ev) => {
    console.log("Clicked question header");
    const surveyUUID = require('querystring').parse(window.location.search.substring(1)).survey_uuid || null;
    if (surveyUUID !== null) {
      navigateTo("survey_stats", { survey_uuid: surveyUUID, question_uuid: question.getUuid() })
    } else {
      console.log("Can't navigate, no survey UUID");
    }
  });

  return container;
}
