export function ascending(extractor) {
  return (obj1, obj2) => {
    const arg1 = extractor(obj1);
    const arg2 = extractor(obj2);
    if (arg1 == arg2) { return 0; }
    return arg1 < arg2 ? -1 : 1;
  };
}

export function descending(extractor) {
  const comparator = ascending(extractor)
  return (obj1, obj2) => comparator(obj2, obj1)
}
