import template from './index.html';
import localize from 'localize';

import { navigateTo } from 'app';
import QuestionStat from 'components/survey_question_statistic';

const strings = {
  en: {

  },
  sv: {

  }
};

export default function Setup(surveyStat, surveyUUID, surveyTitle) {
  const container = document.createElement("div");
  let title = surveyTitle;
  if (title == null || title == "") {
    title = localize({en: "<unknown survey>", sv: "<okänd enkät>"});
  }
  container.innerHTML = template({
    t: localize(strings),
    title: title,
    uuid: surveyUUID,
  });

  const questionsContainer = container.querySelector(".question-stat-container");

  surveyStat.getQuestionStatisticsList().forEach((stat) => {
    questionsContainer.appendChild(QuestionStat(stat));
  });

  container.querySelector(".survey-title").addEventListener("click", (ev) => {
    console.log("Clicked survey header");
    navigateTo("survey_stats", { survey_uuid: surveyUUID })
  })

  return container;
}