export function unpackProtobuf(messageName, messageClass) {
  return async (response) => {
    const actualMessageClass = response.headers.get("X-TummyLab-Message-Class");
    if (actualMessageClass === messageName) {
      return messageClass.deserializeBinary(await response.arrayBuffer());
    } else {
      const error = new Error(`Invalid X-TummyLab-Message-Class\nExpected '${messageName}', got '${actualMessageClass}'`);
      error.response = response;
      throw error;
    }
  }
}

export const checkStatus = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
}
