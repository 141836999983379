import template from './index.html';

import localize from 'localize';

const strings = {
  en: {
    yes: "Yes",
    no: "No",
    override_routine: "Override routine",
    routine_label: "Routine",
    no_routine: "« No routine »",
  },
  sv: {
    yes: "Ja",
    no: "Nej",
    override_routine: "Skriv över rutin",
    routine_label: "Rutin",
    no_routine: "« Ingen rutin »",
  }
};

export default function Setup(relation, payer, routines) {{
  const container = document.createElement("div");
  container.innerHTML = template({
    t: localize(strings)
  });

  const routineMap = {};
  routines.forEach((routine) => {
    routineMap[routine.getUuid()] = routine;
    const option = document.createElement("option");
    option.setAttribute("value", routine.getUuid());
    option.innerText = routine.getInternalName();
    container.querySelector("#routine-selector").appendChild(option);
  });

  function refreshFields() {
    const routineSelector = container.querySelector("#routine-selector");
    if (!relation.getHasRoutineOverride()) {
      container.querySelector("#routine-yes").checked = false;
      container.querySelector("#routine-no").checked = true;
      if (payer.getHasRoutine()) {
        const routine = routineMap[payer.getRoutineUuid()];
        if (routine) {
          routineSelector.value = payer.getRoutineUuid();
        }
      } else {
        routineSelector.value = "default";
      }
      routineSelector.setAttribute("disabled", "disabled");
    } else {
      container.querySelector("#routine-yes").checked = true;
      container.querySelector("#routine-no").checked = false;
      if (relation.getHasRoutine()) {
        const routine = routineMap[relation.getRoutineUuid()];
        if (routine) {
          routineSelector.value = relation.getRoutineUuid();
        }
      } else {
        routineSelector.value = "default";
      }
      routineSelector.removeAttribute("disabled");
    }
  }

  container.querySelectorAll(".radio-routine").forEach((el) => {
    el.addEventListener("change", (ev) => {
      relation.setHasRoutineOverride(ev.target.value == "yes");
      refreshFields();
    });
  });

  container.querySelector("#routine-selector").addEventListener("change", (ev) => {
    if (ev.target.value == "default") {
       relation.setHasRoutine(false);
       relation.setRoutineUuid("");
    } else {
      relation.setHasRoutine(true);
      relation.setRoutineUuid(ev.target.value);
    }
  });

  refreshFields();

  return container;
}}
