import template from './index.html';

import localize from 'localize';
import * as comp from 'comparator';

import { getProgramProgresses } from 'api/tmyAdminV1';

import * as ProgramProgress from 'tummylab-protobuf/js/program.v1/program_progress_pb';
import { User } from 'tummylab-protobuf/js/api.tmy.admin.v1/user_pb';

import ErrorNotification from 'components/notification/error';

const noCaseEquals = (str1, str2) => str1.toUpperCase() == str2.toUpperCase()

const addConfigToProgram = (program, configs) => {
  const config = configs.find(config => noCaseEquals(config.getUuid(), program.getProgramConfigUuid()));
  program.getProgramConfig = () => config
}

const fmtDate = (timestamp) =>
  (new Date(timestamp * 1000)).toISODateString();

const fmtDateTime = (timestamp) => {
  const str = (new Date(timestamp * 1000)).toISOString();
  return (str.slice(0, 10) + " " + str.slice(11, 16));
}

const stateForProgram = (program) => {
  switch(program.getState()) {
    case ProgramProgress.Program.State.ACTIVE:
      return {tag: "info", description: localize({en: "Active", sv: "Aktiv"})};
    case ProgramProgress.Program.State.COMPLETED:
      return {tag: "success", description: localize({en: "Completed", sv: "Avslutad"})};
    case ProgramProgress.Program.State.EXPIRED:
      return {tag: "warning", description: localize({en: "Expired", sv: "Utgången"})};
    case ProgramProgress.Program.State.CANCELLED:
      return {tag: "danger", description: localize({en: "Cancelled", sv: "Avbruten"})};
    default:
      throw "Unknown state";
  }
}

const stateForCourse = (course) => {
  switch(course.getState()) {
    case ProgramProgress.Course.State.ACTIVE:
      return {tag: "info", description: localize({en: "Active", sv: "Aktiv"})};
    case ProgramProgress.Course.State.COMPLETED:
      return {tag: "success", description: localize({en: "Completed", sv: "Avslutad"})};
    default:
      throw "Unknown state";
  }
}

const stateForDay = (day) => {
  switch(day.getState()) {
    case ProgramProgress.Day.State.ACTIVE:
      return {tag: "info", icon: "spinner", description: localize({en: "Active", sv: "Aktiv"})};
    case ProgramProgress.Day.State.COMPLETED:
      return {tag: "success", icon: "check", description: localize({en: "Completed", sv: "Avslutad"})};
    case ProgramProgress.Day.State.SKIPPED:
      return {tag: "warning", icon: "fast-forward", description: localize({en: "Skipped", sv: "Skippad"})};
    case ProgramProgress.Day.State.FAILED:
      return {tag: "danger", icon: "times", description: localize({en: "Failed", sv: "Misslyckad"})};
    default:
      throw "Unknown state";
  }
}

const iconClassesForPlatform = (platform) => {
  switch (platform) {
    case User.Platform.IOS:
      return "fab fa-apple";
    case User.Platform.ANDROID:
      return "fab fa-android";
    case User.Platform.UNKNOWN:
      return "far fa-question-circle";
    default:
      throw "Unknown platform";
  }
}

const addProgramsToUser = async (user, programConfigs) => {
  const programs = await getProgramProgresses(user.getUuid())
  user.getProgramProgresses = () => programs;
  programs.forEach(program => addConfigToProgram(program, programConfigs))
  return user
}

export default function Setup(user, programConfigs) {
  const container = document.createElement("div");
  const username = user.getActivationCode().length > 0 ? user.getActivationCode() : user.getUuid().substr(0,8).toUpperCase()

  addProgramsToUser(user, programConfigs)
    .then((user) => {
      container.innerHTML = template({
        t: localize({
          en: {
            loggedEvents: "logged events",
            noPrograms: "User has no programs",
            noCourses: "First course has not started yet",
            noDays: "First day has not started yet",
            created: "created",
            lastEventAt: "Last event logged at..",
            assessmentUseful: "Useful",
            assessmentWantMore: "Want More"
          },
          sv: {
            loggedEvents: "loggade händelser",
            noPrograms: "Användaren har inget program",
            noCourses: "Första kursen har inte startats än",
            noDays: "Första dagen har inte startats än",
            created: "skapad",
            lastEventAt: "Senaste händelsen loggad..",
            assessmentUseful: "Användbar",
            assessmentWantMore: "Vill ha mer"
          }
        }),
        userId: user.getUuid().toLowerCase(),
        username: username,
        userActivationCodeIssuedTo: user.getActivationCodeIssuedTo(),
        platformIconClasses: iconClassesForPlatform(user.getLastKnownPlatform()),
        accountCreatedAt: fmtDate(user.getCreatedAt()),
        eventCount: user.getEventCount(),
        lastEventAt: fmtDateTime(user.getLastEventAt()),
        programs: user.getProgramProgresses()
          .sort(comp.descending(obj => obj.getStartedAt()))
          .map(program => {
            const programConfig = program.getProgramConfig();
            return {
              name: programConfig ? programConfig.getName() : program.getProgramConfigUuid().substr(0,8).toUpperCase(),
              startedAt: fmtDate(program.getStartedAt()),
              state: stateForProgram(program),
              courses: program.getCoursesList()
                .sort(comp.ascending(obj => obj.getStartedAt()))
                .map(course => {
                  return {
                    name: course.getConfig().getOrdinalTitle() + ":  " + course.getConfig().getTitle(),
                    startedAt: fmtDate(course.getStartedAt()),
                    state: stateForCourse(course),
                    assessmentUseful: course.getHasAssessmentUseful() ?  ("" + course.getAssessmentUseful() + "%") : undefined,
                    assessmentWantMore: course.getHasAssessmentWantMore() ? ("" + course.getAssessmentWantMore() + "%") : undefined,
                    days: course.getDaysList()
                      .sort(comp.ascending(obj => obj.getStartedAt()))
                      .map(day => {
                        const configUuid = day.getConfig().getUuid();
                        const dayIdx = course.getConfig().getDaysList().findIndex(dayConfig => noCaseEquals(dayConfig.getUuid(), configUuid));
                        return {
                          dayCnt: course.getConfig().getDaysList().length,
                          dayNo: dayIdx >= 0 ? dayIdx + 1 : '?',
                          state: stateForDay(day),
                          startedAt: fmtDate(day.getStartedAt()),
                        };
                      })
                  };
                })
            };
          })
      });
    })
    .catch((err) => {
      let errorMsg = err.userDisplayableErrorMessage || localize({
        en: "Failed to display user: " + username,
        sv: "Kunde ej visa användare: " + username
      });

      container.appendChild(ErrorNotification(errorMsg, true));
    });

  return container;
}
