import template from './index.html';

import localize from 'localize';

export default function Setup() {
  const container = document.createElement("div");
  container.innerHTML = template({
    t: localize({
      en: {
        loggedIn: "You are logged in!",
        pickAnOption: "Please pick an option in the menu to proceed."
      },
      sv: {
        loggedIn: "Du är inloggad!",
        pickAnOption: "Välj ett alternativ i menyn för att fortsätta."
      }
    })
  });
  return container;
}
