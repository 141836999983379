import template from './index.html';

import localize from 'localize';
import { navigateTo } from 'app';
import * as comp from 'comparator';

import { getActiveUsers, getProgramConfigs } from 'api/tmyAdminV1';

import ErrorNotification from 'components/notification/error';
import UserProgress from 'components/user_progress';

// First is default filter
const validParamFilters = [
  "exclude_tl", "all"
];

export default function Setup() {
  const paramUserActivationCode = require('querystring').parse(window.location.search.substring(1)).user_activation_code;
  let paramFilter = paramUserActivationCode ? "all" : require('querystring').parse(window.location.search.substring(1)).filter;
  if (!validParamFilters.includes(paramFilter)) {
    paramFilter = validParamFilters[0];
  }

  const container = document.createElement("div");
  container.innerHTML = template({
    t: localize({
      has_user_activation_code_param: !!paramUserActivationCode,
      en: {
        title: paramUserActivationCode ? `User: ${paramUserActivationCode}` : "Active Users",
        loading: "Loading",
        filter: {
          exclude_tl: "External",
          all: "All"
        }
      },
      sv: {
        title: paramUserActivationCode ? `Användare: ${paramUserActivationCode}` : "Aktiva Användare",
        loading: "Laddar",
        filter: {
          exclude_tl: "Externa",
          all: "Alla"
        }
      }
    })
  });

  // Setup filter buttons
  const filterButtons = container.querySelectorAll(".filters .button");
  filterButtons.forEach((button) => {
    const buttonFilter = button.getAttribute("data-filter");
    if (buttonFilter == paramFilter) {
      button.classList.add('is-selected');
      button.classList.add('is-info');
    }

    button.href = `?page=user_progress_dashboard&filter=${buttonFilter}`;
    button.addEventListener("click", (ev) => {
      if (ev.metaKey) { return; }
      ev.preventDefault();

      navigateTo("user_progress_dashboard", { filter: buttonFilter })
    });
  });

  const progressBar = container.querySelector("progress");

  const users = getActiveUsers(paramFilter == "exclude_tl");
  const programConfigs = getProgramConfigs();
  Promise.all([users, programConfigs]).then(([users, programConfigs]) => {
    const usersContainer = container.querySelector(".users");
    progressBar.remove();

    if (users.length > 0) {
      users.sort(comp.descending(obj => obj.getCreatedAt()));

      if (paramUserActivationCode) {
        const paramUserActivationCodeLower = paramUserActivationCode.toLowerCase();
        users = users.filter((user) => user.getActivationCode().toLowerCase() === paramUserActivationCodeLower);
      }

      Promise.all(users.map((user, idx) => {
        const frag = document.createDocumentFragment();
        usersContainer.appendChild(frag);

        sleep(30 * (idx ** 1.1))
          .then(() => usersContainer.appendChild(UserProgress(user, programConfigs)));
      }));
    } else {
      const para = document.createElement("p");
      para.textContent = localize({
        en: "There is no active users..",
        sv: "Det finns inga aktiva användare.."
      });
      usersContainer.appendChild(para);
    }
  })
  .catch((err) => {
    progressBar.remove();

    const errorMsg = err.userDisplayableErrorMessage || localize({
      en: "Failed to get active users.",
      sv: "Kunde ej hämta aktiva användare."
    });

    container.appendChild(ErrorNotification(errorMsg, true));
  });

  return container;
}
