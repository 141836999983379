import template from './index.html';

import localize from 'localize';

import { getExtendableSubscriptions, disableExtendableSubscription, extendExtendableSubscription } from 'api/tmyAdminV1';

import { ExtendableSubscription } from 'tummylab-protobuf/js/api.tmy.admin.v1/extendable_subscription_pb';
import ErrorNotification from 'components/notification/error';

export default function Setup() {
  const container = document.createElement("div");
  container.innerHTML = template({
    t: localize({
      en: {
        title: "Subscriptions which automatically extend",
        loading: "Loading",
        header: {
          user: "User",
          issued_to: "Issued to",
          valid_to: "Valid until",
          product: "Product"
        }
      },
      sv: {
        title: "Prenumerationer som förnyas automatiskt",
        loading: "Laddar",
        header: {
          user: "Användare",
          issued_to: "Utfärdad till",
          valid_to: "Giltig tills",
          product: "Produkt"
        }
      }
    })
  });

  const progressBar = container.querySelector("progress");

  function showError(errorText) {
    container.appendChild(ErrorNotification(errorText, true));
  }

  function loadSubs() {
    progressBar.style.display = "block";
    getExtendableSubscriptions().then((subs) => {
      const subsContainer = container.querySelector(".subscriptions");

      // Clear any previously loaded subs
      while(subsContainer.lastChild) {
        subsContainer.removeChild(subsContainer.lastChild);
      }

      progressBar.style.display = "none";

      // Add all subs to the table
      subs.forEach((sub) => {
        appendSub(subsContainer, sub, loadSubs, showError);
      });
    });
  }

  loadSubs()

  return container;
}

/**
 * Add an extendable subscription as a row in the subscriptions table.
 *
 * @param {*} container The table node that rows are added to
 * @param {ExtendableSubscription} sub The subscription to show
 * @param {*} reloadCallback What to do once an action has completed
 * @param {*} onError What to do if an error occurs
 */
function appendSub(container, sub, reloadCallback, onError) {
  const row = document.createElement("tr");
  function addElem(elem) {
    const col = document.createElement("td");
    col.style.verticalAlign = "middle";
    col.appendChild(elem);
    row.appendChild(col);
  }

  function createText(text) {
    const p = document.createElement("p");
    p.textContent = text;
    return p
  }

  function createButton(text, action) {
    const btn = document.createElement("a");
    btn.setAttribute("class", "button is-primary");
    btn.onclick = () => { action() }
    btn.innerHTML = text;
    return btn;
  }

  const ac = createText(sub.getActivationCode());
  ac.setAttribute("class", "is-size-6 has-text-weight-bold");

  const uid = createText(sub.getUserUuid());
  uid.setAttribute("class", "is-size-7 has-text-weight-normal");

  const user = document.createElement("div");
  user.appendChild(ac);
  user.appendChild(uid);
  addElem(user);
  addElem(createText(sub.getActivationCodeIssuedTo()));
  addElem(createText((new Date(sub.getValidTo() * 1000)).toISODateString()));

  let product = sub.getProductIdentifier();
  if (product == "clinical_report") {
    product = "Clinical Report";
  } else if (product == "logging") {
    product = "Logging";
  }

  const prod = document.createElement("span");
  prod.setAttribute("class", "tag");
  prod.innerHTML = product;

  addElem(prod);
  addElem(createButton(localize({sv: "Förläng 30 dagar", en: "Extend 30 days"}), () => {
    extendSubscription(sub.getSubscriptionUuid(), 30, reloadCallback, onError);
  }));
  addElem(createButton(localize({sv: "Förläng 90 dagar", en: "Extend 90 days"}), () => {
    extendSubscription(sub.getSubscriptionUuid(), 90, reloadCallback, onError);
  }));
  const disable = createButton(localize({sv: "Stäng av", en: "Disable"}), () => {
    disableSubscription(sub.getSubscriptionUuid(), reloadCallback, onError);
  });
  disable.setAttribute("class", "button is-danger");
  disable.setAttribute("title", localize({sv: "Stäng av auto-förnyelse", en: "Disable auto-extending"}))
  addElem(disable);
  container.appendChild(row);
}

/**
* Disable automatic extensions of a specified subscription.
*
* @param {String} subUUID The UUID of the subscription to disable
* @param {*} onDone An action to run once disabling is completed
* @param {*} onError What to do if an error occurs
*/
function disableSubscription(subUUID, onDone, onError) {
  console.log('Disabling automatic extension of subscription ' + subUUID + '...');
  disableExtendableSubscription(subUUID)
    .then(() => {
      console.log('Successfully disabled subscription');
      onDone()
    })
    .catch((err) => {
      console.log('Failed to disable subscription: ', err);
      const errorMsg = err.userDisplayableErrorMessage || localize({
        en: "Failed to disable auto extension of subscription",
        sv: "Kunde ej stänga av förnyelse av prenumeration"
      });
      onError(errorMsg);
    })
}

/**
* Extend a specified subscription by the specified amount of days.
*
* @param {String} subUUID The UUID of the subscription to extend
* @param {Number} daysToExtend The amount of days to extend with
* @param {*} onDone An action to run once extension is completed
* @param {*} onError What to do if an error occurs
*/
function extendSubscription(subUUID, daysToExtend, onDone, onError) {
  console.log('Extending subscription ' + subUUID + ' by ' + daysToExtend + ' days...');
  extendExtendableSubscription(subUUID, daysToExtend)
    .then(() => {
      console.log('Successfully extended subscription');
      onDone()
    })
    .catch((err) => {
      console.log('Failed to extend subscription: ', err);
      const errorMsg = err.userDisplayableErrorMessage || localize({
        en: "Failed to extend subscription",
        sv: "Kunde ej förnya prenumeration"
      });
      onError(errorMsg);
    })
}